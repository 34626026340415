@import '../variables.scss';

@mixin searchResults() {
  .page {
    &--results {
      background: #F6F6F6;
    }
    &--overlay {
      &:after {
        top: 9rem;
        left: 0;
        right: 0;
        bottom: 0;
        height: calc(100% + 36rem);
        background-color: rgba(0, 0, 0, .8);
      }
    }
  }
  .c-card {
    &-info {
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
    &-list {
      border-radius: 0 !important;
      .c-card-image {
        border-radius: 0 !important;
      }
      .c-card-images {
        &__logo-to {
          top: 8% !important;
          left: 1rem;
          border-radius: 0 !important;
          @media (min-width: 992px) {
            top: 1rem !important;
          }
        }
      }
      .c-card-info {
        padding: .5rem 1rem;
        &__destination {
          opacity: .6;
        }
        .c-card-label {
          a {
            color: $wtg-brand-color-03 !important;
          }
        }
      }
    }
    &-booking {
      &__cta {
        align-self: stretch !important;
        &__button {
          background-color: $wtg-brand-color-01;
          border-radius: 0 !important;
          width: 100% !important;
        }
      }
      &__price {
        color: #000 !important;
        font-family: "Montserrat", sans-serif !important;
        font-weight: 400 !important;
        line-height: 1.5;
        span {
          font-weight: 600 !important;
          font-size: 1.5rem !important;
          line-height: 1.5;
        }
      }
      &__info {
        line-height: 1.8 !important;
        font-family: "Montserrat", sans-serif;
      }
    }
    &-label, &-departures__item {
      font-family: "Montserrat", sans-serif;
      line-height: 1.5;
    }
  }
  .wrapper-page__content {
    padding-right: 0;
  }
  .filters {
    box-shadow: 0 0 9px 2px rgba(0, 0, 0, .1);
    border-radius: 0 !important;
    .filters-type {
      &__title {
        button.btn {
          font-weight: 600;
        }
      }
    }
    .filters__check {
      &--specials {
        .form-group-custom {
          border-color: #f509a9 !important;
        }
        .form-check-custom {
          color: #f509a9 !important;
          input {
            &:checked {
              ~ .form-check-custom__check {
                background-color: #f509a9 !important;
              }
            }
          }
          &__check {
            color: #f509a9 !important;
            border-color: #f509a9 !important;
          }
        }
      }
    }
    .ngx-slider  {
      .ngx-slider-pointer {
        background-color: $wtg-brand-color-05 !important;
        &:focus-visible {
          outline: 1px solid $wtg-brand-color-01;
        }
      }
    }
  }
  .wrapper-engine--mobile {
    display: block !important;
    margin-bottom: 0 !important;
    .wrapper-engine {
      &__search {
        display: flex;
        flex-direction: column;
        gap: 0;
        @media (min-width: 992px) {
          gap: 1rem 3rem;
          flex-direction: row;
        }
      }
      &__destination {
        @media (max-width: 991px) {
          font-size: 1.3rem !important;
        }
      }
      &__wrap {
        background: $wtg-brand-color-01;
        color: #fff;
        height: auto;
        padding: .5rem;
        @media (min-width: 992px) {
          height: 70px;
        }
        span {
          i {
            margin-right: .25rem !important;
          }
        }
      }
      &__btn {
        background-color: $wtg-brand-color-01;
        color: #fff;
        @media (min-width: 992px) {
          display: none !important;
        }
      }
      &__cta {
        @media (min-width: 992px) {
          display: none !important;
        }
        .active {
          background-color: $wtg-brand-color-01 !important;
          color: #fff !important;
        }
        .wrapper-engine__cta--element {
          &.disabled {
            display: none;
          }
        }
      }
      &__link {
        text-decoration: underline;
        display: none;
        font-weight: 500;
        @media (min-width: 992px) {
          display: block;
        }
      }
    }
  }
  .wrapper-page__engine.show-engine.show-engine--open {
    display: none;
  }
  .wrapper-page__engine.show-engine {
    display: block;
    z-index: 100;
    @media (min-width: 992px) {
      position: relative;
      box-shadow: none;
      .swiper-button-prev, .swiper-button-next {
        display: none !important;
      }
    }
  }
  .c-card-info {
    &__detail {
      padding: 0 !important;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .c-card-badges {
    margin-top: auto;
  }
  .order-box {
    border-radius: 0 !important;
    @media (min-width: 992px) {
      border-radius: 4px !important;
    }
  }
  .filters {
    margin-top: 0 !important;
    box-shadow: 0 12px 9px 2px rgba(0, 0, 0, .1);
    &__check {
      &--specials {
        .form-group-custom {
          border-color: #f509a9 !important;
        }
        .form-check-custom {
          color: #f509a9;
          &__check {
            border-color: #f509a9 !important;
          }
        }
      }
    }
  }
  .show-filter {
    &--open {
      border-top: 12px solid $wtg-brand-color-01;
    }
  }
  .c-card-list {
    .c-card-tags {
      &__item {
        &.active {
          background-color: $wtg-brand-color-01 !important;
          border-color: $wtg-brand-color-01 !important;
        }
      }
    }
    &--custom {
      border-color: #f509a9 !important;
    }
  }
  ngb-pagination {
    .pagination {
      .page-item {
        .page-link {
          color: $wtg-brand-color-01 !important;
          .sr-only {
            visibility: hidden;
          }
        }
        &.active {
          .page-link {
            background-color: $wtg-brand-color-01 !important;
            border-color: $wtg-brand-color-01 !important;
            color: #fff !important;
          }
        }
      }
    }
  }
}
