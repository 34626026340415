/*
Andrea Calabrò - WebDesigner OTO 2023
 */

@mixin rtl {
  @at-root [dir="rtl"] #{&} {
    @content;
  }
}

$ng-select-highlight: rgba($info, $input-btn-focus-color-opacity) !default;
$ng-select-primary-text: $form-select-color !default;
$ng-select-disabled-text: $form-select-disabled-bg !default;
$ng-select-border: $form-select-border-color !default;
$ng-select-border-radius: 0 !default;
$ng-select-bg: $form-select-bg !default;
$ng-select-selected: lighten($ng-select-highlight, 46) !default;
$ng-select-selected-text: $ng-select-primary-text !default;

// $ng-select-marked: lighten($ng-select-highlight, 48) !default;
$ng-select-marked: $gray-100;
$ng-select-marked-text: $ng-select-primary-text !default;

$ng-select-box-shadow: $form-select-focus-box-shadow !default;
$ng-select-placeholder: lighten($ng-select-primary-text, 40) !default;
$ng-select-height: 42px !default;
$ng-select-value-padding-left: $form-select-padding-x !default;
$ng-select-value-font-size: $form-select-font-size !default;
$ng-select-value-text: $ng-select-primary-text !default;

$ng-select-dropdown-bg: $ng-select-bg !default;
$ng-select-dropdown-border: $ng-select-border !default;
$ng-select-dropdown-optgroup-text: rgba(0, 0, 0, 0.54) !default;
$ng-select-dropdown-optgroup-marked: $ng-select-dropdown-optgroup-text !default;
$ng-select-dropdown-option-bg: $ng-select-dropdown-bg !default;
$ng-select-dropdown-option-text: rgba(0, 0, 0, 0.87) !default;
$ng-select-dropdown-option-disabled: lighten(
  $ng-select-primary-text,
  60
) !default;

$ng-select-input-text: $form-select-color !default;
$ng-select-icon-marked: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{str-replace(#{$link-color}, '#', '%23')}' class='bi bi-check2' viewBox='0 0 16 16'%3E%3Cpath d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");

.input-group {
  .ng-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }
}

.ng-select {
  width: 100%;

  &.ng-select-opened {
    > .ng-select-container {
      background: $ng-select-bg;
      border-color: darken($ng-select-border, 10) $ng-select-border
        lighten($ng-select-border, 5);
      &:hover {
        box-shadow: none;
      }
      .ng-arrow {
        // top: -2px;
        border-color: transparent transparent darken($ng-select-border, 20);
        border-width: 0 5px 5px;
        &:hover {
          border-color: transparent transparent darken($ng-select-border, 60);
        }
      }
    }
    &.ng-select-top {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
    &.ng-select-right {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &.ng-select-left {
      > .ng-select-container {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  &.ng-select-focused {
    > .ng-select-container {
      border-color: $ng-select-highlight;
    }
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $ng-select-highlight;
      // box-shadow: $ng-select-box-shadow;
    }
  }
  &.ng-select-disabled,
  &.disabled,
  &[disabled] {
    > .ng-select-container {
      background-color: $ng-select-disabled-text;
      pointer-events: none;
      cursor: default;
    }
  }
  &.ng-invalid,
  &.is-invalid,
  &.invalid {
    &:not(.ng-untouched) {
      > .ng-select-container {
        border-color: $form-feedback-invalid-color;
        background-image: $form-feedback-icon-invalid;
        background-repeat: no-repeat;
        background-position: right $input-height-inner center;
        background-size: $input-height-inner-half $input-height-inner-half;
      }
    }
  }
  // &.ng-valid,
  // &.is-valid,
  // &.valid {
  //   > .ng-select-container {
  //     border-color: $form-feedback-valid-color;
  //     background-image: $form-feedback-icon-valid;
  //     background-repeat: no-repeat;
  //     background-position: right $input-height-inner center;
  //     background-size: $input-height-inner-half $input-height-inner-half;
  //   }
  // }
  .ng-has-value .ng-placeholder {
    display: none;
  }
  .ng-select-container {
    color: $ng-select-primary-text;
    background-color: $ng-select-bg;
    border-radius: $ng-select-border-radius;
    border: 1px solid $ng-select-border;
    min-height: $ng-select-height;
    align-items: center;
    box-shadow: none;
    transition: $form-select-transition;
    &:hover {
      box-shadow: none;
    }
    .ng-value-container {
      align-items: center;
      padding-left: $ng-select-value-padding-left;
      @include rtl {
        padding-right: $ng-select-value-padding-left;
        padding-left: 0;
      }
      .ng-placeholder {
        color: $ng-select-placeholder;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
      }
    }
  }
  &.ng-select-single {
    .ng-select-container {
      min-height: $ng-select-height;
      .ng-value-container {
        .ng-value {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.6;
        }
        .ng-input {
          top: 9px;
          left: 0;
          padding-left: $form-select-padding-x;
          padding-right: $form-select-indicator-padding;
          @include rtl {
            padding-right: $form-select-padding-x;
            padding-left: $form-select-indicator-padding;
          }
        }
      }
    }
  }
  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-disabled-text;
        border: 1px solid lighten($ng-select-border, 10);
        .ng-value-label {
          padding: 0 5px;
        }
      }
    }
    .ng-select-container {
      .ng-value-container {
        padding-top: 5px;
        padding-left: 7px;
        @include rtl {
          padding-right: 7px;
          padding-left: 0;
        }
        .ng-value {
          font-size: $ng-select-value-font-size;
          margin-bottom: 5px;
          color: $ng-select-value-text;
          background-color: $ng-select-selected;
          border-radius: 2px;
          margin-right: 5px;
          @include rtl {
            margin-right: 0;
            margin-left: 5px;
          }
          &.ng-value-disabled {
            background-color: $ng-select-disabled-text;
            .ng-value-label {
              padding-left: 5px;
              @include rtl {
                padding-left: 0;
                padding-right: 5px;
              }
            }
          }
          .ng-value-label {
            display: inline-block;
            padding: 1px 5px;
          }
          .ng-value-icon {
            display: inline-block;
            padding: 1px 5px;
            &:hover {
              background-color: darken($ng-select-selected, 5);
            }
            &.left {
              border-right: 1px solid darken($ng-select-selected, 10);
              @include rtl {
                border-left: 1px solid darken($ng-select-selected, 10);
                border-right: none;
              }
            }
            &.right {
              border-left: 1px solid darken($ng-select-selected, 10);
              @include rtl {
                border-left: 0;
                border-right: 1px solid darken($ng-select-selected, 10);
              }
            }
          }
        }
        .ng-input {
          padding: 0 0 3px 3px;
          @include rtl {
            padding: 0 3px 3px 0;
          }
          > input {
            color: $ng-select-input-text;
          }
        }
        .ng-placeholder {
          top: 5px;
          padding-bottom: 5px;
          padding-left: 3px;
          @include rtl {
            padding-right: 3px;
            padding-left: 0;
          }
        }
      }
    }
  }
  .ng-clear-wrapper {
    color: darken($ng-select-border, 20);
    &:hover .ng-clear {
      color: $red;
    }
  }
  .ng-spinner-zone {
    padding: 5px 5px 0 0;

    @include rtl {
      padding: 5px 0 0 5px;
    }
  }
  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 0;
    @include rtl {
      padding-left: 0px;
      padding-right: 0;
    }
    &:hover {
      .ng-arrow {
        border-top-color: darken($ng-select-border, 40);
      }
    }
    .ng-arrow {
      content: "";
      height: 16px !important;
      width: 12px !important;
      background-image: escape-svg($form-select-indicator);
      background-repeat: no-repeat;
      background-position: center;
      background-size: $form-select-bg-size;
    }
  }

  &.ng-select-sm {
    > .ng-select-container {
      min-height: calc(1.5em + 0.5rem + 2px);
      font-size: 0.7109375rem;
    }
  }
  &.ng-select-lg {
    > .ng-select-container {
      font-size: 1.015625rem;
    }
  }
}

.ng-dropdown-panel {
  background-color: $ng-select-dropdown-bg;
  border: 1px solid $ng-select-dropdown-border;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;

  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: $ng-select-border-radius;
    border-top-left-radius: $ng-select-border-radius;
    border-bottom-color: lighten($ng-select-border, 10);
    margin-bottom: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: $ng-select-border-radius;
          border-top-left-radius: $ng-select-border-radius;
        }
      }
    }
  }
  &.ng-select-right {
    left: 100%;
    top: 0;
    border-top-right-radius: $ng-select-border-radius;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-bottom-color: lighten($ng-select-border, 10);
    margin-bottom: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: $ng-select-border-radius;
        }
      }
    }
  }
  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-top-color: lighten($ng-select-border, 10);
    margin-top: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: $ng-select-border-radius;
          border-bottom-left-radius: $ng-select-border-radius;
        }
      }
    }
  }
  &.ng-select-left {
    left: -100%;
    top: 0;
    border-top-left-radius: $ng-select-border-radius;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-bottom-color: lighten($ng-select-border, 10);
    margin-bottom: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-left-radius: $ng-select-border-radius;
        }
      }
    }
  }
  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }
  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 5px 7px;
  }
  .ng-dropdown-panel-items {
    // height: 100% !important;
    // max-height: 100% !important;
    max-height: 220px !important;
    .ng-optgroup {
      user-select: none;
      padding: $form-select-padding-x $form-select-padding-y;
      font-weight: 500;
      color: $ng-select-dropdown-optgroup-text;
      cursor: pointer;
      &.ng-option-disabled {
        cursor: default;
      }
      &.ng-option-marked {
        background-color: $white;
      }
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $ng-select-dropdown-optgroup-marked;
        background-color: $ng-select-selected;
        font-weight: 600;
      }
    }
    .ng-option {
      position: relative;
      // background-color: $ng-select-dropdown-option-bg;
      background-color: transparent;
      color: $ng-select-dropdown-option-text;
      padding: calc(#{$form-select-padding-y} * 2)
        $form-select-indicator-padding calc(#{$form-select-padding-y} * 2)
        $form-select-padding-x;
      line-height: 1.7;
      white-space: normal !important;
      @media (min-width: 992px) {
        padding: $form-select-padding-y $form-select-indicator-padding
          $form-select-padding-y $form-select-padding-x;
      }
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $primary;
        background-color: transparent;
        background-image: $ng-select-icon-marked;
        background-position: $form-select-bg-position;
        background-size: 16px;
        background-repeat: no-repeat;

        .ng-option-label {
          font-weight: 400;
        }
      }
      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: $primary;
      }
      &.ng-option-disabled {
        color: $ng-select-dropdown-option-disabled;
      }
      &.ng-option-child {
        padding-left: 22px;
        @include rtl {
          padding-right: 22px;
          padding-left: 0;
        }
      }
      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
        @include rtl {
          padding-left: 5px;
          padding-right: 0;
        }
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 12px;
        width: calc(100% - 24px);
        height: 1px;
        background: #d9d9d6;
      }
      &:last-of-type {
        margin-bottom: 1rem;
      }
    }
  }

  @include rtl {
    direction: rtl;
    text-align: right;
  }
}
