@import "../variables.scss";

@mixin pageLoader() {
  .page-loader {
    font-family: "Montserrat", sans-serif;
    .sk-circle {
      margin: 50px auto;
    }
  }
}

