@import "../variables.scss";

@mixin utilities() {
  &.is-widget {
    border-radius: 0;
    .widget-container {
      &__background {
        border-radius: 0;
      }
    }
  }
  .navbar {
    .navbar-toggler {
      border-radius: 0;
      
      &--no-border {
        border: 0;
      }
    }
  }
  .e-title {
    text-align: center;
    h2 {
      color: $wtg-brand-color-01;
    }
    &__with-divider {
      hr {
        &.hr {
          &--brand {
            border-color: $wtg-brand-color-05;
            margin: 0 auto;
            width: 150px;
          }
        }
      }
    }
  }
  hr {
    &.hr {
      &--brand {
        border-color: $wtg-brand-color-01;
      }
    }
  }
  .generic-page {
    line-height: 1.7;
    h2 {
      font-size: 1.6rem;
    }
    hr {
      margin-bottom: 2rem !important;
    }
    h3 {
      line-height: 1.4;
      margin-bottom: .75rem;
      font-size: 1.25rem;
    }
  }
  /*.cs--horizontal .ui-component__children {
    justify-content: center;
    > * {
      flex: 0 0 calc(20% - 2rem);
    }
  }*/
  .ui-component__content-wrapper .e-title h2 {
    font-size: 1.35rem !important;
  }
  .form-check-custom input:checked ~ .form-check-custom__check, .form-check-custom input:checked ~ .form-check-custom__radio {
    background-color: $wtg-brand-color-01 !important;
    border-color: $wtg-brand-color-01 !important;
  }
  .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    background-color: $wtg-brand-color-01 !important;
  }
  .swal2-icon.swal2-error {
    border-color: $wtg-brand-color-01 !important;
    color: $wtg-brand-color-01 !important;
  }
  .logo-geo {
    margin-left: auto;
  }
  .form-control-custom:active:not([disabled]), .form-control-custom:focus:not([disabled]) {
    border-color: $wtg-brand-color-01 !important;
    outline: 0;
    box-shadow: 0 0 0.3rem 0.2rem rgba(58, 167, 243, 0.3);
  }
  .e-icons-wrap__icon {
    color: $wtg-brand-color-02 !important;
    i {
      background-color: #e9e9e9 !important;
    }
    &--blue {
      i {
        color: $wtg-brand-color-02 !important;
      }
    }
  }
  .form-check--custom [type=radio]:checked + label:after, .form-check--custom [type=radio]:not(:checked) + label:after {
    background: $wtg-brand-color-01 !important;
    border-color: $wtg-brand-color-01 !important;
  }
  .form-check--custom [type=radio]:checked + label:before, .form-check--custom [type=radio]:not(:checked) + label:before {
    border-color: $wtg-brand-color-01 !important;
  }
}
