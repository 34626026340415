.u-scrollbar {
  //Firefox
  scrollbar-color: #005B9A #d2d2d2;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.4em;
  } 
  &::-webkit-scrollbar-track {
    background: #d2d2d2;
    border-radius: 0.4em;
  }
  &::-webkit-scrollbar-thumb {
    background: #005B9A;
    border-radius: 0.4em;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #005B9A;
  }
}