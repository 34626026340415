@font-face {
  font-family: 'icons-generic';
  src:
    url('../assets/fonts/icons-generic.ttf?kux39o') format('truetype'),
    url('../assets/fonts/icons-generic.woff?kux39o') format('woff'),
    url('../assets/fonts/icons-generic.svg?kux39o#icons-generic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="g-icon-"], [class*=" g-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons-generic' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.g-icon-smartbox:before {
  content: "\e9a4";
}
.g-icon-service:before {
  content: "\e9a2";
}
.g-icon-show-more-eye:before {
  content: "\e9a3";
}
.g-icon-circle:before {
  content: "\e97a";
}
.g-icon-list-down:before {
  content: "\e960";
}
.g-icon-list-reorder:before {
  content: "\e972";
}
.g-icon-list-up:before {
  content: "\e974";
}
.g-icon-free-canc:before {
  content: "\e95f";
}
.g-icon-guide:before {
  content: "\e942";
}
.g-icon-fedora:before {
  content: "\e943";
}
.g-icon-virus:before {
  content: "\e944";
}
.g-icon-insurance:before {
  content: "\e945";
}
.g-icon-24hours:before {
  content: "\e948";
}
.g-icon-luggage:before {
  content: "\e940";
}
.g-icon-transfer:before {
  content: "\e941";
}
.g-icon-admin:before {
  content: "\e903";
}
.g-icon-affiliates:before {
  content: "\e904";
}
.g-icon-archive-h:before {
  content: "\e906";
}
.g-icon-cache:before {
  content: "\e90f";
}
.g-icon-call-center:before {
  content: "\e910";
}
.g-icon-catalogues:before {
  content: "\e912";
}
.g-icon-chest:before {
  content: "\e913";
}
.g-icon-costa-archive:before {
  content: "\e918";
}
.g-icon-costa-requests:before {
  content: "\e919";
}
.g-icon-cruise:before {
  content: "\e91c";
}
.g-icon-cruise-engine:before {
  content: "\e91a";
}
.g-icon-custom-holiday:before {
  content: "\e91d";
}
.g-icon-dashboard:before {
  content: "\e91e";
}
.g-icon-engine-dest:before {
  content: "\e926";
}
.g-icon-estimate-request:before {
  content: "\e929";
}
.g-icon-flash-holiday:before {
  content: "\e92e";
}
.g-icon-flash-holiday-2-0:before {
  content: "\e92d";
}
.g-icon-gdpr:before {
  content: "\e92f";
}
.g-icon-info-dest:before {
  content: "\e934";
}
.g-icon-insert-product:before {
  content: "\e937";
}
.g-icon-layout:before {
  content: "\e93d";
}
.g-icon-network:before {
  content: "\e949";
}
.g-icon-news:before {
  content: "\e94b";
}
.g-icon-newsletter:before {
  content: "\e94c";
}
.g-icon-pacchetti:before {
  content: "\e950";
}
.g-icon-pagamenti:before {
  content: "\e951";
}
.g-icon-prev-banner:before {
  content: "\e958";
}
.g-icon-privacy:before {
  content: "\e95b";
}
.g-icon-quota-insert:before {
  content: "\e95e";
}
.g-icon-repricing:before {
  content: "\e961";
}
.g-icon-request-cruise:before {
  content: "\e962";
}
.g-icon-request-holiday:before {
  content: "\e963";
}
.g-icon-reserved-area:before {
  content: "\e965";
}
.g-icon-seo2:before {
  content: "\e968";
}
.g-icon-services-maintenance:before {
  content: "\e969";
}
.g-icon-specials:before {
  content: "\e96d";
}
.g-icon-statistics:before {
  content: "\e96f";
}
.g-icon-tag:before {
  content: "\e973";
}
.g-icon-totem:before {
  content: "\e975";
}
.g-icon-tour:before {
  content: "\e976";
}
.g-icon-web-page:before {
  content: "\e97e";
}
.g-icon-web-service:before {
  content: "\e97f";
}
.g-icon-website-management:before {
  content: "\e980";
}
.g-icon-widget:before {
  content: "\e981";
}
.g-icon-working:before {
  content: "\e983";
}
.g-icon-www:before {
  content: "\e985";
}
.g-icon-cruise-balcony:before {
  content: "\e90c";
}
.g-icon-cruise-external:before {
  content: "\e928";
}
.g-icon-cruise-internal:before {
  content: "\e938";
}
.g-icon-cruise-suite:before {
  content: "\e971";
}
.g-icon-sortby:before {
  content: "\e939";
}
.g-icon-female:before {
  content: "\e90e";
}
.g-icon-mail:before {
  content: "\e917";
}
.g-icon-male:before {
  content: "\e923";
}
.g-icon-close-circle:before {
  content: "\e932";
}
.g-icon-bus-filled:before {
  content: "\e99a";
}
.g-icon-car-filled:before {
  content: "\e99b";
}
.g-icon-flight-filled:before {
  content: "\e99c";
}
.g-icon-search:before {
  content: "\e99d";
}
.g-icon-seat-bus:before {
  content: "\e99e";
}
.g-icon-seat-flight:before {
  content: "\e99f";
}
.g-icon-ship-filled:before {
  content: "\e9a0";
}
.g-icon-train-filled:before {
  content: "\e9a1";
}
.g-icon-chevron-down-circle:before {
  content: "\e908";
}
.g-icon-chevron-down:before {
  content: "\e98a";
}
.g-icon-chevron-left-circle:before {
  content: "\e98b";
}
.g-icon-chevron-left:before {
  content: "\e98c";
}
.g-icon-chevron-right-circle:before {
  content: "\e98d";
}
.g-icon-chevron-right:before {
  content: "\e98e";
}
.g-icon-chevron-up-circle:before {
  content: "\e98f";
}
.g-icon-chevron-up:before {
  content: "\e990";
}
.g-icon-key:before {
  content: "\e991";
}
.g-icon-media-pic:before {
  content: "\e992";
}
.g-icon-media-play:before {
  content: "\e993";
}
.g-icon-moon:before {
  content: "\e994";
}
.g-icon-pin-moon:before {
  content: "\e995";
}
.g-icon-pin:before {
  content: "\e996";
}
.g-icon-remove:before {
  content: "\e997";
}
.g-icon-sun:before {
  content: "\e998";
}
.g-icon-wheat:before {
  content: "\e999";
}
.g-icon-add:before {
  content: "\e902";
}
.g-icon-add-cruise:before {
  content: "\e900";
}
.g-icon-add-holiday:before {
  content: "\e901";
}
.g-icon-add-inverse:before {
  content: "\e927";
}
.g-icon-archive:before {
  content: "\e907";
}
.g-icon-archive-download:before {
  content: "\e905";
}
.g-icon-arrow-long-left:before {
  content: "\e909";
}
.g-icon-arrow-long-right:before {
  content: "\e90a";
}
.g-icon-assistance:before {
  content: "\e90b";
}
.g-icon-bed:before {
  content: "\e90d";
}
.g-icon-close:before {
  content: "\e911";
}
.g-icon-cloud:before {
  content: "\e914";
}
.g-icon-company:before {
  content: "\e915";
}
.g-icon-confirmed:before {
  content: "\e916";
}
.g-icon-cruise-search:before {
  content: "\e91b";
}
.g-icon-date:before {
  content: "\e91f";
}
.g-icon-departure:before {
  content: "\e920";
}
.g-icon-drag-n-drop:before {
  content: "\e922";
}
.g-icon-duplicate:before {
  content: "\e924";
}
.g-icon-edit:before {
  content: "\e986";
}
.g-icon-edit-alt:before {
  content: "\e954";
}
.g-icon-enable-sale:before {
  content: "\e925";
}
.g-icon-euro:before {
  content: "\e933";
}
.g-icon-excursion:before {
  content: "\e92a";
}
.g-icon-filter:before {
  content: "\e92b";
}
.g-icon-flagged:before {
  content: "\e92c";
}
.g-icon-flight:before {
  content: "\e95d";
}
.g-icon-globe:before {
  content: "\e95c";
}
.g-icon-guest:before {
  content: "\e96b";
}
.g-icon-hand-stop-sale:before {
  content: "\e930";
}
.g-icon-holiday-search:before {
  content: "\e931";
}
.g-icon-hotel:before {
  content: "\e921";
}
.g-icon-info:before {
  content: "\e936";
}
.g-icon-info-rounded:before {
  content: "\e935";
}
.g-icon-itinerary:before {
  content: "\e93a";
}
.g-icon-kebab:before {
  content: "\e93b";
}
.g-icon-layout-management:before {
  content: "\e93c";
}
.g-icon-length:before {
  content: "\e93e";
}
.g-icon-light:before {
  content: "\e93f";
}
.g-icon-link-alt:before {
  content: "\e94d";
}
.g-icon-lock:before {
  content: "\e946";
}
.g-icon-logout:before {
  content: "\e947";
}
.g-icon-new-voucher:before {
  content: "\e94a";
}
.g-icon-note:before {
  content: "\e94e";
}
.g-icon-notify:before {
  content: "\e979";
}
.g-icon-operatives:before {
  content: "\e94f";
}
.g-icon-payment-method:before {
  content: "\e952";
}
.g-icon-pdf:before {
  content: "\e953";
}
.g-icon-place:before {
  content: "\e955";
}
.g-icon-png:before {
  content: "\e988";
}
.g-icon-position-horizontal:before {
  content: "\e956";
}
.g-icon-position-vertical:before {
  content: "\e957";
}
.g-icon-pricing-absolute:before {
  content: "\e959";
}
.g-icon-print:before {
  content: "\e95a";
}
.g-icon-request-status:before {
  content: "\e964";
}
.g-icon-response:before {
  content: "\e966";
}
.g-icon-seo:before {
  content: "\e967";
}
.g-icon-ship:before {
  content: "\e96a";
}
.g-icon-social:before {
  content: "\e96c";
}
.g-icon-star:before {
  content: "\e96e";
}
.g-icon-stop-sale:before {
  content: "\e970";
}
.g-icon-trash:before {
  content: "\e977";
}
.g-icon-treatment:before {
  content: "\e978";
}
.g-icon-universal:before {
  content: "\e97b";
}
.g-icon-user:before {
  content: "\e97c";
}
.g-icon-voucher-dark:before {
  content: "\e987";
}
.g-icon-warning:before {
  content: "\e97d";
}
.g-icon-warning-circle:before {
  content: "\e989";
}
.g-icon-work-in-progress:before {
  content: "\e982";
}
.g-icon-wtg:before {
  content: "\e984";
}
.g-icon-medal:before {
  content: "\e9a5";
}