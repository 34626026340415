/* _form-control-custom.scss */
.form-control-custom {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  transition: border-color ease-out 0.22s;
  line-height: 1.2;
  font-size: .875rem;
  &:not(textarea) {
    height: calc(1.5em + 0.93rem + 2px); /* 38px */
  }
  &.custom-select {
    height: calc(1.3em + 0.9rem + 2px);
  }
  &:hover {
    border-color: $brand-color-01;
    transition: border-color ease-in 0.33s;
  }
  &:active,
  &:focus {
    &:not([disabled]) {
      border-color: $brand-color-01;
      outline: 0;
      box-shadow: 0 0 0.3rem 0.2rem rgba($brand-color-01, 0.3);
    }
  }

  &.valid,
  &.is-valid {
    border-color: $color-success;
    padding-right: calc(1.5em + 0.63rem);
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-274 -13)' fill='none' fill-rule='evenodd'%3E%3Crect stroke='%2334BFA3' fill='%23FFF' x='.5' y='.5' width='299' height='36' rx='4'/%3E%3Cpath d='M288.81 13l1.19 1.196-10.183 10.236-.59.568-.594-.572L274 19.771l1.19-1.196 4.037 4.059L288.811 13z' fill='%2334BFA3' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E")
    #fff no-repeat top 0.73rem right 0.63rem/1rem;
    &:active,
    &:focus {
      box-shadow: 0 0 0.3rem 0.2rem rgba($color-success, 0.3);
      border-color: $color-success;
    }
    ~ .form-group-custom__caption {
      color: $color-success;
    }
  }

  &.invalid,
  &.is-invalid {
    border-color: $color-danger;
    padding-right: calc(1.5em + 0.63rem);
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-274 -11)' fill='none' fill-rule='evenodd'%3E%3Crect stroke='%23F4516C' fill='%23FFF' x='.5' y='.5' width='' height='' rx='4'/%3E%3Cpath fill='%23F4516C' fill-rule='nonzero' d='M275.125 11L274 12.125 280.875 19 274 25.875 275.125 27 282 20.125 288.875 27 290 25.875 283.125 19 290 12.125 288.875 11 282 17.875z'/%3E%3C/g%3E%3C/svg%3E")
    #fff no-repeat top 0.63rem right 0.63rem/1rem;
    &:active,
    &:focus {
      box-shadow: 0 0 0.3rem 0.2rem rgba($color-danger, 0.3);
      border-color: $color-danger;
    }
    ~ .form-group-custom__caption {
      color: $color-danger;
    }
  }

  &.warning,
  &.is-warning {
    border-color: $color-warning;
    padding-right: calc(1.5em + 0.63rem);
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-274 -12)' fill='none' fill-rule='evenodd'%3E%3Crect stroke='%23FFA322' fill='%23FFF' x='.5' y='.5' width='299' height='36' rx='4'/%3E%3Cpath d='M282 12l-.51.883-6.998 12.234L274 26h16l-.492-.883-6.998-12.234L282 12zm0 2.355l5.977 10.468h-11.954L282 14.355zm-.583 3.992v3.532h1.166v-3.532h-1.166zm0 4.12v1.178h1.166v-1.177h-1.166z' fill='%23FFA322' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E")
    #fff no-repeat top 0.63rem right 0.63rem/1rem;
    &:active,
    &:focus {
      box-shadow: 0 0 0.3rem 0.2rem rgba($color-warning, 0.3);
      border-color: $color-warning;
    }
    ~ .form-group-custom__caption {
      color: $color-warning;
    }
  }

  &.select-custom {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z' style='fill:%23909090;' /%3E%3C/svg%3E")
    #fff no-repeat top 0.63rem right 0.63rem/1rem;
    transition: border-color ease-out 0.22s;
    cursor: pointer;

    &.form-control::-ms-expand {
      display: none;
    }

    &.invalid,
    &.is-invalid {
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z' style='fill:%23909090;'/%3E%3C/svg%3E")
      no-repeat right 0.63rem center/1rem,
      url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-274 -11)' fill='none' fill-rule='evenodd'%3E%3Crect stroke='%23F4516C' fill='%23FFF' x='.5' y='.5' width='' height='' rx='4'/%3E%3Cpath fill='%23F4516C' fill-rule='nonzero' d='M275.125 11L274 12.125 280.875 19 274 25.875 275.125 27 282 20.125 288.875 27 290 25.875 283.125 19 290 12.125 288.875 11 282 17.875z'/%3E%3C/g%3E%3C/svg%3E")
      #fff no-repeat center right 1.88rem/1rem;
      ~ .form-group-custom__caption {
        color: $color-danger;
      }
    }

    &.valid,
    &.is-valid {
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z' style='fill:%23909090;'/%3E%3C/svg%3E")
      no-repeat right 0.63rem center/1rem,
      url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-274 -13)' fill='none' fill-rule='evenodd'%3E%3Crect stroke='%2334BFA3' fill='%23FFF' x='.5' y='.5' width='299' height='36' rx='4'/%3E%3Cpath d='M288.81 13l1.19 1.196-10.183 10.236-.59.568-.594-.572L274 19.771l1.19-1.196 4.037 4.059L288.811 13z' fill='%2334BFA3' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E")
      #fff no-repeat center right 1.88rem/1rem;
      ~ .form-group-custom__caption {
        color: $color-success;
      }
    }

    &.warning,
    &.is-warning {
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z' style='fill:%23909090;'/%3E%3C/svg%3E")
      no-repeat right 0.63rem center/1rem,
      url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-274 -12)' fill='none' fill-rule='evenodd'%3E%3Crect stroke='%23FFA322' fill='%23FFF' x='.5' y='.5' width='299' height='36' rx='4'/%3E%3Cpath d='M282 12l-.51.883-6.998 12.234L274 26h16l-.492-.883-6.998-12.234L282 12zm0 2.355l5.977 10.468h-11.954L282 14.355zm-.583 3.992v3.532h1.166v-3.532h-1.166zm0 4.12v1.178h1.166v-1.177h-1.166z' fill='%23FFA322' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E")
      #fff no-repeat center right 1.88rem/1rem;
      ~ .form-group-custom__caption {
        color: $color-warning;
      }
    }
  }
  &--h48 {
    &:not(textarea) {
      height: calc(2.3em + 0.87rem + 2px); /* 48px */
    }
    &.select-custom {
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z' style='fill:%23909090;' /%3E%3C/svg%3E")
      #fff no-repeat top 0.63rem right 0.63rem/1.75rem;
    }
  }
}
/* _form-control-custom.scss END */
