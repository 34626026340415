@import "../variables.scss";

@mixin home() {
  ui-card {
    .c-card {
      &.c-card--vertical {
        border-radius: 0 !important;
        font-family: "Montserrat", sans-serif;
        .c-card {
          &__pic {
            border-radius: 0 !important;
            &:before {
              border-radius: 0 !important;
              content: none;
            }
            img {
              border-radius: 0 !important;
            }
            .c-card__favorite {
              border-radius: 0;
            }
          }
          &__header {
            margin-bottom: .5rem;
          }
          &__body {
            .custom-list {
            }
          }
          &__prices {
            small {
              font-family: "Montserrat", sans-serif;
              font-size: .7125rem;
            }
          }
          &__pp {
            line-height: 1;
          }
          &__duration {
          }
        }
      }
    }
    .c-home-banner {
      @media (min-width: 992px) {
        margin: 0 auto;
      }
      img {
        border-radius: 0;
      }
    }
  }
  .ui-card--slide {
    .container {
      @media (min-width: 1200px) and (max-width: 1439px) {
        max-width: 960px;
      }
    }
    .image {
      .c-slide__top-row {
        padding: 0 2rem;
        @media (min-width: 1200px) {
          padding: 0;
        }
      }
      .title {
        margin-top: 1rem;
        h2 {
          font-size: 1.4rem !important;
          margin: .125rem 0 1rem 0 !important;
          @media (min-width: 992px) {
            font-size: 1.8rem !important;
          }
        }
        > h6 {
          font-weight: 400 !important;
          text-transform: uppercase;
          @media (min-width: 992px) {
            position: absolute;
            left: 0;
            top: -0.75rem;
          }
        }
        .prepend-category {
          margin-top: .5rem;
        }
      }
    }
  }
  .c-slide {
    &__header {
      position: relative;
      .logo {
        border-radius: 0 !important;
        aspect-ratio: 2/1;
        height: 3rem !important;
        margin-right: 1rem !important;
        padding: .5rem !important;
        @media (min-width: 992px) {
          height: 4.5rem !important;
          padding: 1rem !important;
        }
        img {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
    &__price {
      border-radius: 0 !important;
      background: $wtg-brand-color-01 !important;
      font-family: "Montserrat", sans-serif;
      .price-value, small, .price-suffix {
        font-family: "Montserrat", sans-serif !important;
      }
    }
  }
  .ui-grid--news-container, .brand-container {
    background: $wtg-brand-color-01 !important;
    .ui-component__children {
      width: 94% !important;
      margin: 0 3%;
    }
    .news-header {
      justify-content: center;
      position: relative;
      h2 {
        color: #fff !important;
      }
      .news-controls {
        width: 100%;
        justify-content: space-between;
        position: absolute;
        transform: translateY(520%);
        top: -13.3rem;
        @media (min-width: 992px) {
          top: unset;
        }
        .custom-swiper-control {
          background-color: transparent;
          color: #fff;
          padding: .5rem;
          border-radius: 0;
          i {
            color: #fff !important;
            font-size: 1.5rem;
          }
          @media(min-width: 992px) {
            &.swiper-button-disabled {
              opacity: .5;
            }
          }
        }
      }
    }
    hr {
      display: none;
    }
    .news-preview {
      .card-wrapper {
        border-radius: 0 !important;
        img {
          border-radius: 0 !important;
        }
        h4 {
          background-color: #fff;
          padding: 1rem !important;
          text-align: center;
          text-shadow: none !important;
          color: $wtg-brand-color-01 !important;
          font-size: 1rem;
          text-transform: uppercase;
          //position: absolute;
          //bottom: -8px;
          //left: 0;
          //right: 0;
        }
      }
    }
  }

  ui-grid {
    .banner-utili {
      .ui-component__children {
        justify-content: center;
        > * {
          max-width: 45%;
          @media (min-width: 992px) {
            flex: 0 0 calc(20% - 2rem);
          }
        }
      }
    }
    .destination-container-wtg, .brand-container {
      //max-width: 100% !important;
      //padding: 0 !important;
      .ui-component__children {
        gap: 0;
        > * {
          flex: 1 1 50%;
          aspect-ratio: 1/1;
          @media (min-width: 540px) {
            flex: 1 1 50%;
            aspect-ratio: 1/1;
          }
          @media (min-width: 768px) {
            flex: 0 0 50%;
            aspect-ratio: 1/1;
          }
          @media (min-width: 992px) {
            flex: 0 0 25%;
            aspect-ratio: 1/1;
          }
        }
        .c-home-destination {
          border-radius: 0;
          overflow: hidden;
          padding: 0;
          &__image {
            border-radius: 0 !important;
            transform: scale(1, 1);
            transition: transform 0.6s ease;
            &:before {
              border-radius: 0;
              background: linear-gradient(to bottom, rgba(0, 91, 154, 0) 40%, rgb(66, 66, 66, .5) 100%);
            }
            @media (min-width: 992px) {
              &:hover {
                transform: scale(1.1, 1.1);
              }
            }
          }
          &__title {
            text-shadow: 0 0 3px #000;
            position: absolute;
            bottom: 0 !important;
            top: unset !important;
            padding: 0.75rem 1rem !important;
            h3 {
              font-size: 1.5rem !important;
            }
          }
        }
      }
      .ui-card--destination {
        margin-bottom: 0;
        height: 100%;
        .c-card-home__cta {
          display: none;
        }
      }
    }

    .brand-container {
      .ui-component__children {


        > * {
          padding: .25rem;
        }
      }
    }
  }
  ui-slider {
    .ui-component__content-wrapper .oto-swiper-wrapper {
      @media (min-width: 992px) {
        aspect-ratio: 2.8/1 !important;
      }
      + .slider-wrap__engine {
        margin-top: 0;
        @media (min-width: 768px) and (max-width: 1199px) {
          margin-top: -10px;
        }
        @media (min-width: 1200px) {
          margin-top: -88px;
        }
      }
      swiper {
        .swiper.s-wrapper {
          .swiper-pagination {
            display: none;
            /*@media (min-width: 768px) {
              bottom: 1rem;
            }
            @media (min-width: 1200px) {
              bottom: 6rem;
            }*/
          }
          .swiper-button-prev, .swiper-button-next {
            background-color: rgba(255, 255, 255, .15);
            border-radius: 0;
            border: 2px solid #fff;
            text-shadow: 0 0 4px #000;
          }
        }
      }
    }
    .engine {
      .container {
        @media (min-width: 1200px) and (max-width: 1439px) {
          max-width: 960px;
        }
      }
    }
  }
  .request-appointment {
    padding: 0 !important;
    background-color: #f3f3f3;
    .custom-button {
      font-weight: 400 !important;
    }
    &__header {
      h6 {
        font-family: "Montserrat", sans-serif !important;
        color: $wtg-brand-color-01 !important;
        font-weight: 600 !important;
        font-size: 24px !important;
      }
    }
    &__subtitle {
      font-family: "Montserrat", sans-serif !important;
      font-size: 1rem !important;
    }
  }
  .custom-btn {
    &.read-all {
      font-family: "Montserrat", sans-serif;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      &.btn--brand-color-03 {
        background-color: #fff;
        color: $wtg-brand-color-01;
        border-color: #fff;
        box-shadow: 0 0 13px rgb(0, 0, 0, .15);
      }
    }
    &.read-more {
      @media (min-width: 992px) {
        display: none !important;
      }
    }
  }
  .request-appointment__header {
    justify-content: center;
    .image-wrapper {
      padding: 0 !important;
      margin: 0 !important;
    }
    .content-wrapper {
      margin-left: 1.5rem;
      flex: unset !important;
    }
  }
  ngb-timepicker .ngb-tp .ngb-tp-input-container .btn-link {
    color: $wtg-brand-color-01 !important;
  }
  .request-appointment {
    .content-wrapper {
      margin-bottom: 1.5rem;
    }
    ngb-datepicker {
      [ngbDatepickerDayView] {
        &.bg-primary {
          background-color: $wtg-brand-color-01 !important;
        }
      }
    }
    select {
      border-radius: 0;
    }
  }
  .home-section.newsletter {
    h5 {
      color: $wtg-brand-color-01 !important;
    }
    .newsletter-box {
      border-radius: 0 !important;

      &__image {
        border-radius: 0 !important;
      }
    }
    button {
      background-color: $wtg-brand-color-01 !important;
      color: #fff;
      border-color: $wtg-brand-color-01 !important;
    }
    input {
      border-radius: 0 !important;
    }

  }
}

