.modal {
  &-content {
    //max-height: 91vh;
    @media (min-width: 992px) {
      max-height: unset;
    }
  }
  &-body {
    //max-height: 96vh;
    //overflow-y: scroll;
    @media (min-width: 992px) {
      max-height: unset;
      overflow: visible;
    }
  }
  &-custom {
    &__header {
      border-bottom: 0;
      text-align: center;
      align-items: center;
      justify-content: center;
      .modal-title {
        color: #343434;
        font-size: 1.25rem;
        font-family: "Roboto Slab", sans-serif;
        font-weight: 600;
        letter-spacing: -.0125rem;
        text-align: center;
        margin-top: .5rem;
        i {
          display: inline-block;
          margin-right: .25rem;
          color: #89BAE4;
        }
      }
      .close {
        font-size: .875rem;
        background: none;
        border: none;
        font-weight: 600;
      }
    }
    &-body {
      background-color: #fff;
      padding: .25rem 1rem;
    }
  }
}

.logo-geo {
  text-align: right;
  span {
    display: block;
    margin-bottom: .1rem;
    font-size: .75rem;
    color: #9F9E9E;
  }
}

