@import '../variables.scss';

@mixin footer() {
  footer {
    .footer {
      &-copyright {
        background-color: $wtg-brand-color-05;
        &__text {
          color: #fff;
        }
      }
      &-body {
        font-family: "Montserrat", sans-serif;
        .company-details {
          span {
            color: #fff !important;
            line-height: 1.6;
          }
        }
      }
      &-main {
        background-color: $wtg-brand-color-01;
        .footer-title {
          color: #fff;
        }
      }
      &-menu {
        &__link {
          padding: .5rem 0;
          a {
            color: #fff !important;
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            display: inline-block;
            position: relative;
          }
        }
      }
    }
  }
  .contacts-menu {
    &__navbar {
      .nav-item {
        .nav-link {
          color: #fff !important;
        }
      }
    }
  }
  .footer-social-menu {
    margin-top: 2rem !important;
  }
}
