@import "configurations";

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

html,
body {
  font-family: $font-secondary;
  font-size: 16px;
  color: setColor('dark', 'darken-80');
  line-height: 1.2;
  background-color: setColor('ui', 'background');
}

h1,
h1.h1,
h2,
h2.h2,
h3,
h3.h3,
h4,
h4.h4,
h5,
h5.h5 {
  font: normal map-get($font-weights, "bold") 1rem/1.2 $font-primary;
  margin-bottom: map-get($spacers, 1);

  &--sec {
    font-family: $font-secondary;
  }

  @media (min-width: map-get($grid-breakpoints, sm)) {
    font: normal map-get($font-weights, "bold") 1rem/1.1 $font-primary;
  }
}

h1,
h1.h1 {
  font-size: 2rem;
  line-height: 1.25;
  &--sec {
    font-size: 1.5rem;
  }
  @media (min-width: map-get($grid-breakpoints, sm)) {
    font-size: 2.5rem;
    line-height: 1.2;
    &--sec {
      font-size: 2.5rem;
    }
  }
}

h2,
h2.h2 {
  font-size: 1.75rem;
  &--sec {
    font-size: 1.25rem;
  }
  @media (min-width: map-get($grid-breakpoints, sm)) {
    font-size: 2rem;
    &--sec {
      font-size: 2rem;
    }
  }
}

h3,
h3.h3 {
  font-size: 1.25rem;
  line-height: 1.2;
  @media (min-width: map-get($grid-breakpoints, sm)) {
    font-size: 1.2rem;
  }
}

h4,
h4.h4 {
  font-size: 1rem;
  @media (min-width: map-get($grid-breakpoints, sm)) {
    font-size: 1.25rem;
  }
}

h5,
h5.h5 {
  font-size: 1rem;
}

p {
  //font: normal map-get($font-weights, "normal") 1rem/1.5 $font-secondary;
  letter-spacing: normal;
  // color: setColor('dark', 'darken-60');
  margin-bottom: map-get($spacers, 1);
}

small {
  font: normal map-get($font-weights, "normal") .8125rem/normal $font-secondary;
  letter-spacing: normal;
  color: setColor('dark', 'darken-80');
}
