@import "../variables.scss";

@mixin noResults() {
  .c-zero-results {
    &__message {
      h3 {
        color: $wtg-brand-color-03 !important;
      }
    }
  }
}
