@import "common";

.page {
  background: #f8fafb;
  position: relative;
  &.is-widget {
    max-width: calc(100% - .5rem);
    margin: 0 auto;
    @media (min-width: 992px) {
      max-width: calc(100% - 2rem);
    }
  }
  &__content {
    position: relative;
  }
  &--overlay {
    position: relative;
    z-index: 10;
    &:after {
      content: "";
      position: absolute;
      top: 11.6rem;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 10;
    }
  }
  &__link {
    font-size: 0.875rem;
    color: $brand-color-02;
    display: inline-block;
    margin-left: 0.25rem;
    margin-bottom: 0;
    cursor: pointer;
    span {
      text-decoration: underline;
    }
    i {
      font-size: 0.75rem;
      display: inline-block;
      margin-right: 0.35rem;
    }
    &--brand-color {
      color: $brand-color-01;
    }
    &--underline {
      text-decoration: underline;
    }
    &--bold {
      font-weight: bold;
      i {
        font-weight: bold;
      }
    }
  }
}
.wrapper-page {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
  &--bg-white {
    background-color: #fff;
  }
  &--padding {
    padding-top: 1rem;
  }
  &--border-radius {
    border-radius: 4px;
  }
  &--column {
    flex-direction: column;
    padding: 2rem 0 3rem 0;
  }
  &__aside {
    flex: 0 0 100%;
    max-width: 100%;
    @media (min-width: 992px) {
      flex: 0 0 25%;
      padding: 0 1rem 0 0;
      margin-bottom: 3rem;
    }
  }
  &__content {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    @media (min-width: 992px) {
      flex: 1 0 75%;
      padding: 0 1.5rem 0 0;
    }
    @media (min-width: 1199px) {
      padding: 0 2rem 0 0;
    }
  }
  &__engine {
    background-color: #fff;
  }
}

.wrapper-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column-reverse;
  margin: 0 1.5rem;
  @media (min-width: 768px) {
    flex-flow: row;
    justify-content: space-between;
    margin: 1rem 0 3rem;
    button.btn {
      flex: 0 0 calc((100% - 1rem) / 2);
    }
  }
}
