@import "colors";

hr {
  &.hr {
    border-top: 1px solid #000;
    margin-top: 5px;
    margin-bottom: 25px;
    width: 100%;
    &--short {
      width: 60%;
      margin-left: 0;
    }
    &--list {
      width: 300px;
      margin-left: 0;
    }
    &--heavy {
      border-width: 4px;
    }
    &--dashed {
      border-style: dashed;
    }
    &--center {
      margin: 0 auto;
    }
    &--margin-big {
      margin-top: 25px;
      margin-bottom: 25px;
      border-top: 2px solid #fff;
    }
    &--white {
      border-color: #fff;
    }
    &--grey {
      border-color: #d5d5d5;
    }
    &--brand {
      border-color: $brand-color-02;
    }
    &--title {
      width: 290px;
      margin-left: 0;
      @media (min-width: 480px) {
        width: 330px;
      }
    }
  }
  &.dashed-border {
    position: relative;
    border-color: transparent;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: calc(100% + 1px);
      transform: translateY(-50%);
      background-image: linear-gradient(to right, #D3D3D3 50%, transparent 50%);
      background-size: 20px;
      z-index: 0;
      border-radius: 0.5rem;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: calc(100% + 1px);
      transform: translateY(-50%);
      background-image: linear-gradient(to right, #D3D3D3 50%, transparent 50%);
      background-size: 20px;
      z-index: 0;
      border-radius: 0.5rem;
    }
    &.hr--white {
      &::before, &::after {
        background-image: linear-gradient(to right, #fff 50%, transparent 50%);
      }
    }
    &.hr--light-blue {
      &::before, &::after {
        background-image: linear-gradient(to right, $brand-color-02 50%, transparent 50%);
      }
    }
    &--small {
      &:after, &:before {
        background-size: 10px;
      }
    }
    &--lighter {
      &:after, &:before {
        background-image: linear-gradient(to right, #D3D3D3 50%, transparent 50%);
      }
    }
  }
}
