@import '../variables.scss';

@mixin header() {
  .top-bar-menu {
    background-color: $wtg-brand-color-01 !important;
    padding: .25rem 0;
    font-family: "Montserrat", sans-serif;
    &__navbar {
      &--socials {
        li {
          a {
            i {
              border: 1px solid #fff;
              padding: 0.25rem;
              border-radius: 4px;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .navbar {
    padding: .5rem 0 0;
    .main-nav {
      &-item {
        &__link {
          font-family: "Montserrat", sans-serif;
          padding: .3rem 1rem;
        }
      }
    }
  }
  .main-nav {
    padding-top: 0 !important;
    &-item {
      @media (min-width: 992px) {
        border-right: 1px solid $wtg-brand-color-01;
      }
    }
    &__brand {
      margin-left: 1rem;
      @media (min-width: 992px) {
        margin-left: 0;
      }
      img {
        max-height: 90px !important;
      }
    }
    .nav-item {
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 0;
        i {
          font-size: 1rem;
          color: $wtg-brand-color-01;
          margin-right: .75rem;
        }
      }
    }
    .nav-link {
      &__info {
        font-size: .75rem;
        small {
          display: block;
          font-size: .6rem;
          opacity: .6;
        }
      }
    }
    .navbar--socials {
      //border-bottom: 1px solid #dfdfdf;
      flex-direction: column;
      @media (min-width: 992px) {
        flex-direction: row;
      }
    }
    .top-bar-menu {
      &__navbar--contacts {
        .nav-link {
          margin: 0 .5rem;
        }
      }
      &__navbar--socials {
        margin-left: 1rem;
        align-self: flex-start;
        @media (min-width: 992px) {
          align-self: center;
        }
        .nav-item {
          i {
            border: 1px solid $wtg-brand-color-01;
            border-radius: 0;
          }
        }
      }
    }
  }
  .logo-geo {
    span {
      display: inline-block;
      margin-right: 0.5rem;
      font-size: 0.75rem;
      color: #ffffff;
    }
  }
  .container-wtg-header {
    align-items: flex-start;
    flex-direction: column;
    @media (min-width: 992px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
}
