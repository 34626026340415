@import "../variables.scss";

@mixin destinations() {
  .c-destinations {
    &__item {
      border-radius: 0;
    }
    &__img {
      border-radius: 0;
    }
  }
}

