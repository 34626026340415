.btn {
  font-family: 'Inter', sans-serif;
  font-size: 1rem; // 16px
  color: #fff;
  text-align: center;
  font-weight: 700;
  border-radius: 50px;
  padding: 0.625rem 1.875rem;
  //min-width: 210px;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  border: 2px solid transparent;
  @media (max-width: 991px) {
    width: 100%;
    margin: .35rem 0;
  }
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  &.btn {
    &--outline {
      background-color: #fff;
      border: 1px solid #888;
      color: #888;
      &:hover {
        border-color: #000;
        color: #000;
        transition: color 0.2s ease-in;
      }
      &.disabled, &[disabled] {
        background-color: #f7f7f7;
        border-color: #888;
        color: #888;
        cursor: default;
      }
    }

    &--blue {
      background-color: $btn-bg-blue-01;
      color: #fff;
      border-color: $btn-bg-blue-01;
      &:hover {
        background-color: $btn-bg-blue-02;
        border-color: $btn-bg-blue-02;
        color: #fff;
      }
      &.disabled, &[disabled] {
        background-color: $btn-bg-blue-03;
        border-color: $btn-bg-blue-03;
        color: #888;
        cursor: default;
      }
    }


    &--green {
      background-color: $btn-bg-green-01;
      color: #fff;
      border-color: $btn-bg-green-01;
      &:hover {
        background-color: $btn-bg-green-02;
        border-color: $btn-bg-green-02;
      }
      &.disabled, &[disabled] {
        background-color: $btn-bg-blue-03;
        border-color: $btn-bg-blue-03;
        color: #888;
        cursor: default;
      }
    }

    &--orange {
      //max-width: fit-content;
      background-color: $btn-bg-orange-02;
      color: #fff;
      border-color: $btn-bg-orange-02;
      &:hover {
        background-color: $btn-bg-orange-01;
        border-color: $btn-bg-orange-01;
        color: #fff;
      }
      &.disabled, &[disabled] {
        background-color: $btn-bg-orange-03;
        border-color: $btn-bg-orange-03;
        color: #888;
        cursor: default;
      }
    }

    &--light-blue {
      background-color: $btn-bg-light-blue-01;
      color: #fff;
      border-color: $btn-bg-light-blue-01;
      &:hover {
        background-color: $btn-bg-light-blue-02;
        border-color: $btn-bg-light-blue-02;
        color: #fff;
      }
      &.disabled, &[disabled] {
        background-color: $btn-bg-light-blue-03;
        border-color: $btn-bg-light-blue-03;
        color: #888;
        cursor: default;
      }
    }

    &--blue-outline {
      background-color: #fff;
      border: 1px solid $btn-bg-blue-01;
      color: $btn-bg-blue-01;
      &:hover {
        border-color: $btn-bg-blue-02;
        color: $btn-bg-blue-02;
        transition: color 0.2s ease-in;
      }
      &.disabled, &[disabled] {
        background-color: #fff;
        border-color: #888;
        color: #888;
        cursor: default;
      }
    }

    &--transparent-outline {
      background-color: transparent;
      border: 1px solid $btn-bg-blue-01;
      color: $btn-bg-blue-01;
      &:hover {
        border-color: $btn-bg-blue-02;
        color: #fff;
        background-color: $btn-bg-blue-02;
        transition: color 0.2s ease-in;
      }
      &.disabled, &[disabled] {
        background-color: transparent;
        border-color: #888;
        color: #888;
        cursor: default;
      }
    }

    &--orange-outline {
      background-color: #fff;
      border: 1px solid $btn-bg-orange-01;
      color: $btn-bg-orange-01;
      &:hover {
        border-color: $btn-bg-orange-02;
        color: $btn-bg-orange-02;
        transition: color 0.2s ease-in;
      }
      &.disabled, &[disabled] {
        background-color: #fff;
        border-color: #888;
        color: #888;
        cursor: default;
      }
    }

    &--yellow {
      background-color: $btn-bg-yellow-01;
      color: #3A539B;
      border-color: $btn-bg-yellow-01;
      &:hover {
        background-color: $btn-bg-yellow-02;
        border-color: $btn-bg-yellow-02;
      }
      &.disabled, &[disabled] {
        background-color: $btn-bg-yellow-03;
        border-color: $btn-bg-yellow-03;
        color: #888;
        cursor: default;
      }
    }

    &--yellow-outline {
      background-color: #fff;
      border: 1px solid $btn-bg-yellow-01;
      color: $btn-bg-yellow-01;
      &:hover {
        border-color: $btn-bg-yellow-02;
        color: $btn-bg-yellow-02;
        transition: color 0.2s ease-in;
      }
      &.disabled, &[disabled] {
        background-color: #fff;
        border-color: #888;
        color: #888;
        cursor: default;
      }
    }

    &--zeus {
      background-color: $btn-bg-zeus-01;
      color: #fff;
      border-color: $btn-bg-zeus-01;
      &:hover {
        background-color: $btn-bg-zeus-02;
        border-color: $btn-bg-zeus-02;
        color: #fff;
      }
      &.disabled, &[disabled] {
        background-color: $btn-bg-zeus-03;
        border-color: $btn-bg-zeus-03;
        color: #888;
        cursor: default;
      }
    }

    &--zeus-outline {
      background-color: #fff;
      border: 1px solid $btn-bg-zeus-01;
      color: $btn-bg-zeus-01;
      &:hover {
        border-color: $btn-bg-zeus-02;
        color: $btn-bg-zeus-02;
        transition: color 0.2s ease-in;
      }
      &.disabled, &[disabled] {
        background-color: #fff;
        border-color: #888;
        color: #888;
        cursor: default;
      }
    }

    &--light-blue-outline {
      background-color: #fff;
      border: 1px solid $btn-bg-light-blue-01;
      color: $btn-bg-light-blue-01;
      &:hover {
        border-color: $btn-bg-light-blue-02;
        color: $btn-bg-light-blue-02;
        transition: color 0.2s ease-in;
      }
      &.disabled, &[disabled] {
        background-color: #fff;
        border-color: #888;
        color: #888;
        cursor: default;
      }
    }

    &--grey {
      background-color: $btn-bg-grey;
      color: #fff;
      border-color: $btn-bg-grey;
      &:hover {
        background-color: $btn-bg-grey;
        border-color: $btn-bg-grey;
      }
      &.disabled, &[disabled] {
        background-color: $btn-bg-grey;
        border-color: $btn-bg-grey;
        color: #888;
        cursor: default;
      }
    }

    &--brand-color-03 {
      background-color: $btn-bg-orange-02;
      color: #fff;
      border-color: $btn-bg-orange-02;
      &:hover {
        background-color: $btn-bg-orange-01;
        border-color: $btn-bg-orange-01;
      }
      &.disabled, &[disabled] {
        background-color: $btn-bg-orange-03;
        border-color: $btn-bg-orange-03;
        color: #888;
        cursor: default;
      }
    }

    &--light-weight {
      font-weight: 300;
    }

    &--wide {
      padding: 0.625rem 7.115rem;
    }

    &--uppercase {
      text-transform: uppercase;
    }

    &--font-normal {
      font-weight: 400;
    }

    &--lg {
      padding: 2.063rem 4.665rem;
    }

    &--sm {
      padding: 0.438rem 1rem;
    }

    &--xs {
      padding: 0.313rem 0.775rem;
      font-size: 0.75rem;
    }

    &--sm-large {
      padding: 0.438rem 3.5rem;
    }

    &--xs-large {
      padding: 0.313rem 2.5rem;
      font-size: 0.75rem;
    }

    &--filter {
      background-color: #fff;
      border-radius: 0;
      color: #888;
      letter-spacing: 0;
      padding: .5rem 1rem;
      min-width: 100px;
      border-right: 1px solid #f7f7f7;
      border-bottom: 2px solid #ccc;
      &.active {
        color: #000;
        border-bottom-color: $brand-color-03;
      }
    }
    &--accordion {
      color: #000;
      padding: 0;
      font-weight: 700;
      display: block;
      width: 100%;
      text-align: left;
    }
    &--accordion .icon-minus::before,
    &--accordion .icon-plus::before {
      content: '\2212'; /* Unicode per il carattere - */
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 700;
    }
    &--accordion .icon-plus::before {
      content: '\002B'; /* Unicode per il carattere + */
    }

    &--mobile {
      font-size: .875rem;
      text-transform: uppercase;
      width: max-content;
      @media (min-width: 992px) {
        display: none;
      }
      i {
        display: inline-block;
        margin-left: .5rem;
      }
    }

    &--border-thick {
      border-width: 2px !important;
    }
  }
  &__uppercase {
    text-transform: uppercase;
    display: block;
    font-weight: 700;
    font-size: 1.15rem;
    letter-spacing: 1px;
  }
  &__light {
    font-weight: 300;
  }
  &-wrapper {
    &--center {
      text-align: center;
    }
  }
}
