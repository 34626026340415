.c-chips {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.c-chip {
  display: inline-block;
  text-align: center;
  font-size: .875rem;
  font-weight: map-get($font-weights, medium);
  line-height: 1;
  color: setColor('dark', 'darken-80');
  padding: map-get($spacers, 1) map-get($spacers, 3);
  border-radius: 1rem;
  background-color: #f3f3f3;
  border: solid 2px transparent;
  cursor: pointer;
  white-space: nowrap;

  &:before {
    content: "";
    display: inline-block;
    height: .875rem;
    vertical-align: middle;
  }

  &:hover {
    &:not(.c-chip--active) {
      text-decoration: underline;
    }
  }

  &--active,
  &--is-active,
  &--service {
    background-color: setColor('neutral', 'n01');
    border-color: transparentize(setColor('primary', 'p01'), 0.9);
    box-shadow: 0 0 10px -2px #f38933;
    text-decoration: none !important;
    cursor: default;
  }

  &--orange {
    background-color: $brand-color-03;
    color: setColor('light', 'lighten-100');
  }

}
