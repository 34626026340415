@import "../variables.scss";

@mixin modal() {
  .modal-backdrop {
    background: #000 !important;
  }
  .modal {
    .modal-dialog {
      &.modal-md {
        @media (min-width: 992px) {
          max-width: 930px;
        }
        .modal-body {
          ngb-datepicker.datepicker {
            [ngbDatepickerDayView] {
              color: #6d6d6d !important;
              &:hover {
                color: #fff !important;
                background-color: $wtg-brand-color-01 !important;
              }
            }
          }
          @media (min-width: 1440px) {
            padding: 0 5rem;
            ngb-datepicker.datepicker {
              .ngb-dp-month {
                padding: 0 2.5rem;
              }
            }
          }
        }
      }
      &.modal-xl, &.modal-lg, &.modal-md {
        @media (min-width: 1200px) {
          max-width: 960px;
        }
        @media (min-width: 1440px) {
          max-width: 1140px;
        }
      }
    }
    .modal-content {
      border: 0;
      border-radius: 0;
      .holiday-guests {
        border-radius: 0;
        .spinner-counter {
          &__btn {
            background-color: $wtg-brand-color-01;
          }
        }
      }
      .modal-header {
        .modal-title, .modal-close {
          font-family: "Montserrat", sans-serif;
          color: $wtg-brand-color-02;
        }
        .modal-close {
          opacity: .7;
        }
      }
    }
    .modal-body {
      .destinations-thumbnail {
        .destinations-thumbnail {
          &__element {
            border-radius: 0;
            text-align: center;
            height: 130px;
            justify-content: center;
            border: 0;
          }
          &__title {
            font-family: "Montserrat", sans-serif;
            color: #fff;
            text-shadow: 0 0 4px #000;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
            position: relative;
            z-index: 2;
            background-color: transparent;
            font-size: .75rem;
            @media (max-width: 319px) {
              font-size: .55rem;
            }
            @media (min-width: 768px) {
              font-size: 1rem;
            }
          }
          &__img {
            border-radius: 0 !important;
            border: 0;
            position: absolute;
            width: 100%;
            filter: saturate(120%) contrast(110%);
            z-index: 0;
            height: 130px;
            &:before {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              content: '';
              background: rgb(0,0,0);
              background: linear-gradient(180deg, rgba(13,13,13,0) 0%, rgba(0,0,0,0.5) 35%, rgba(0,0,0,0.5) 65%, rgba(13, 13, 13, 0) 100%);
              z-index: 1;
            }
          }
          &__content {
            .swiper {
              &.s-wrapper {
                .swiper-button-next, .swiper-button-prev {
                  border-radius: 0;
                }
              }
            }
          }
        }
      }
      .destinations-itineraries {
        &__element {
          border-radius: 5px !important;
          border-color: $wtg-brand-color-02;
          font-family: 'Montserrat', sans-serif;
          &.active {
            border-color: $wtg-brand-color-03;
            background-color: $wtg-brand-color-03;
          }
        }
      }
      .destinations-airport {
        &__element {
          font-family: "Montserrat", sans-serif;
          border-radius: 0;
          &.active {
            background-color: $wtg-brand-color-01;
          }
          &[disabled], &.disabled, &:disabled {
            opacity: .4;
            pointer-events: none;
            i {
              color: #888 !important;
            }
          }
        }
      }
      .datepicker {
        position: relative;
        .ngb-dp-header {
          .ngb-dp-navigation-select {
            @media (min-width: 992px) {
              position: absolute;
              top: -3rem;
              left: 45%;
            }
            select {
              border-radius: 0;
              border: 0;
              width: 70px;
              margin-right: 0 !important;
              padding: 0;
              color: $wtg-brand-color-02;
              font-weight: 700;
              font-family: "Montserrat", sans-serif;
              text-transform: uppercase;
              font-size: 1rem;
              &:focus {
                outline: 0;
                box-shadow: none;
              }
            }
          }
        }
        .ngb-dp-months {
          position: relative;
          @media (min-width: 992px) {
            top: -2.3rem;
          }
          .ngb-dp-month-name {
            margin-bottom: 1rem;
            text-transform: uppercase;
            font-weight: 600;
          }
          .ngb-dp-day {
            [ngbdatepickerdayview] {
              margin: .5rem auto;
              border-radius: 0;
            }
          }
        }
      }
      .htb-modal {
        &__title {
          font-size: 1.35rem;
        }
        &__description {
          text-align: center;
        }
      }
      .choice {
        &__container {
          max-width: 70%;
          margin: 0 auto;
          gap: 1rem;
          flex-direction: column;
          @media (min-width: 992px) {
            flex-direction: row;
          }
        }
        &__single {
          background-color: $wtg-brand-color-01;
          padding: 1rem;
          height: fit-content;
        }
        &__icon {
          font-size: 1.5rem;
          vertical-align: middle;
          margin-bottom: 0;
        }
        &__text {
          display: inline-block;
          margin-left: .5rem;
          font-size: 1rem;
          font-weight: 600;
          font-family: "Montserrat", sans-serif;
        }
      }
      &.modal__airport-body {
        max-height: calc(100vh - 135px);
        overflow-y: auto;
      }
    }
    .modal-footer {
      .destinations-thumbnail__search {
        .input-group-custom {
          input {
            border-radius: 0 !important;
          }
        }
      }
    }
  }
  .destinations-airport {
    &__element {
      i {
        color: $wtg-brand-color-01 !important;
      }
    }
  }
}

