.u-background {
  display: block;

  h1.h1 {
    font-weight: map-get($font-weights, medium);
  }

  &--cream {
    background-color: #f5ecdd !important;
    display: block;
  }
  &--green {
    background-color: #e6f9e9 !important;
    display: block;
  }
  &--yellow {
    background-color: #fed404 !important;
    display: block;
  }
  &--beige {
    background-color: #f8f6ef !important;
    display: block;
  }
  &--orange {
    background-color: #f06c00 !important;
  }
  &--white {
    background-color: #fff !important;
  }
  &--black {
    background-color: #333 !important;
    color: #fff;
    display: block;
    h1.h1,
    h2.h2,
    h3.h3,
    h4.h4,
    h5.h5 {
      color: #fff;
    }
    p {
      color: #fff;
    }
    small,
    small span {
      color: setColor('light', 'lighten-60');
    }
    .t-outline {
      color: setColor('light', 'lighten-100');
      border: map-get($btn, border-width) solid setColor('light', 'lighten-100');
    }
  }
}
