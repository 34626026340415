/* Importing Bootstrap SCSS file. */

@import "mixin/ellipsis";

@import "colors";
@import "page";
@import "typography";
@import "btn";
@import "icons";
@import "forms";
@import "common";
@import "components/ui-components";
@import "./styles/utilis/u-scrollbar.scss";

// refactoring wip
@import "./styles/refactoring/utilities/generic.scss";
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/functions";

$success: #01c120;
$info: #0072ca;
$warning: #ffb602;
$danger: #fb0000;
$input-border-radius: 1rem;
$border-radius: 1rem;
$tabloid: rgba(0, 113, 163, 1);

// Breadcrumb
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// BS Modifier
@import "./styles/refactoring/configurations";

@import "~bootstrap/scss/bootstrap";

// Base
@import "./styles/refactoring/colors";
@import "./styles/refactoring/typography";

// Components
@import "./styles/refactoring/components/c-chips";

// Utilities
@import "./styles/refactoring/utilities/u-scrollbar";
@import "./styles/refactoring/utilities/u-scrollsnap";
@import "./styles/refactoring/utilities/u-background";
@import "./styles/refactoring/utilities/u-text";

// end of refactoring wip

@import "ng-select-bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

:root {
  --swiper-theme-color: #d3d3d3 !important;
}

html:has(.is-widget) {
  overflow: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

.is-b2b {
  background-color: transparent;
}

.is-wtg {
  //zoom: 0.8;

  .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
    background: transparent;
  }
  .ui-modal {
    &__body {
      top: 25% !important;
      max-height: 60rem;
    }
  }
}

.is-widget {
  border-radius: 50px;
  .no-container-widget {
    // max-width: 100%;
  }
}

img[src=""] {
  content: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

*[dynamicNavigation] > * {
  pointer-events: none;
}

.ng-select {
  &.stops,
  &.agency-ref {
    .ng-select-container.ng-has-value {
      height: unset;
    }
  }
}

.alert {
  a {
    color: $color-info;
    text-decoration: underline;
  }
}

.u-ellipsis {
  &--lin-1,
  &--lin-1 p {
    @include uEllipsis($lines: 1);
  }
  &--lin-2,
  &--lin-2 p {
    @include uEllipsis($lines: 2);
  }
  &--lin-3,
  &--lin-3 p {
    @include uEllipsis($lines: 3);
  }
  &--lin-4,
  &--lin-4 p {
    @include uEllipsis($lines: 4);
  }
  &--lin-5,
  &--lin-5 p {
    @include uEllipsis($lines: 5);
  }
}

.cs--h-12 {
  .c-home-banner {
    img {
      object-fit: cover;
      min-height: unset !important;
      box-shadow: none !important;
      width: 100%;
      height: auto;
      @media (max-width: 600px) {
        aspect-ratio: 10 / 7;
      }
    }
  }
}

.navbar .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.container {
  @media (min-width: 1400px) {
    max-width: 1140px;
  }
}

.c-home-destination {
  box-sizing: content-box;
  padding-bottom: 1rem;
  margin-bottom: 1rem !important;
}

.cs--h-19 {
  .c-home-banner {
    //min-width: 320px;
    max-width: 280px;
    margin: 0 auto 1rem auto;
    display: block;
    img {
      //min-height: 15rem;
      border-radius: 1rem;
      box-shadow: 0 0 9px 0 rgb(0 0 0 / 25%);
      width: 100%;
      min-width: 20%;
      background-position: 50% 70%;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

$maxChildren: 16;
@for $i from 1 through $maxChildren {
  .child-#{$i} {
    grid-area: child-#{$i};
  }
}

.news-previews {
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
  }
}

.is-widget.modal-open .modal {
  // max-height: 800px;
  @media (min-width: 992px) {
    .modal-body {
      max-height: 65vh;
      overflow: auto;
      scrollbar-gutter: stable;
      /* total width */
      &::-webkit-scrollbar {
          background-color: #fff;
          width: 16px;
      }

      /* background of the scrollbar except button or resizer */
      &::-webkit-scrollbar-track {
          background-color: #fff;
      }

      /* scrollbar itself */
      &::-webkit-scrollbar-thumb {
          background-color: #babac0;
          border-radius: 16px;
          border: 4px solid #fff;
      }

      /* set button(top and bottom of the scrollbar) */
      &::-webkit-scrollbar-button {
          display:none;
      }

    }
    // margin: 2rem;
    // width: calc(100% - 4rem);
  }
}

.modal-open.block-page-scroll:not(.is-widget) {
  overflow: hidden;
}

.is-widget .lightbox-shown {
  max-height: 840px;
}

.fs-xs {
  font-size: 0.875rem !important;
}

.img-cover {
  object-fit: cover !important;
}

.img-contain {
  object-fit: contain !important;
}

.fw-medium {
  font-weight: 600 !important;
}

app-element-loader {
  display: contents;
}

.modal-login-intervieweb {
  .modal-sm {
    margin-top: 10%;
    @media (min-width: 576px) {
      max-width: 500px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

// LOCAL DEVELOPMENT WHITE-LABEL - COMMENTARE IMPORT A FINE SVILUPPO
@import "./brands/wtg/partner-styles.scss";

.pill {
  //properties
  border-radius: 0.4rem !important;
  padding: 0.2rem 1rem !important;
  position: relative;
  border: 1px solid var(--bs-primary);
  color: var(--bs-primary);

  &:hover {
    background-color: var(--bs-primary);
    color: white !important;
    transition-property: background-color, color;
    transition-duration: 0.33s;
    transition-timing-function: ease-out;
  }

  //varianti
  &-tabloid {
    background-color: white;
    border: 1px solid $tabloid;
    box-shadow: 0px 0px 8px -2px $tabloid;
    color: $tabloid;

    &:hover {
      background-color: $tabloid;
      color: white;
      transition-property: background-color, color;
      transition-duration: 0.33s;
      transition-timing-function: ease-out;
    }
    &--wtgStyle{
      display: block !important;
      text-align: left !important;
      padding: .25rem .5rem !important;
      span{
        font-size: .625rem !important;
      }
    }
    &--promoCosta{
      display: inline-block !important;
      font-size: .7rem !important;
      padding: .35rem .7rem;
      align-self: end;
      border-radius: .35rem;
    }
  }
}
