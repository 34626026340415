@import "variables";
@import "icons-generic";
@import "icons-tags";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8sqnae');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8sqnae#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?8sqnae') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?8sqnae') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?8sqnae##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-skype {
  &:before {
    content: $icon-skype;
  }
}
.icon-pdf-download {
  &:before {
    content: $icon-pdf-download;
  }
}
.icon-home-heart {
  &:before {
    content: $icon-home-heart;
  }
}
.icon-shield-check {
  &:before {
    content: $icon-shield-check;
  }
}
.icon-24-hours {
  &:before {
    content: $icon-24-hours;
  }
}
.icon-creativity {
  &:before {
    content: $icon-creativity;
  }
}
.icon-pencil-ruler {
  &:before {
    content: $icon-pencil-ruler;
  }
}
.icon-shield-empty {
  &:before {
    content: $icon-shield-empty;
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil;
  }
}
.icon-parking {
  &:before {
    content: $icon-parking;
  }
}
.icon-rental {
  &:before {
    content: $icon-rental;
  }
}
.icon-arrow {
  &:before {
    content: $icon-arrow;
  }
}
.icon-group-people-sm {
  &:before {
    content: $icon-group-people-sm;
  }
}
.icon-age {
  &:before {
    content: $icon-age;
  }
}
.icon-target {
  &:before {
    content: $icon-target;
  }
}
.icon-arrows {
  &:before {
    content: $icon-arrows;
  }
}
.icon-sunflower {
  &:before {
    content: $icon-sunflower;
  }
}
.icon-options {
  &:before {
    content: $icon-options;
  }
}
.icon-order-list {
  &:before {
    content: $icon-order-list;
  }
}
.icon-pin-map {
  &:before {
    content: $icon-pin-map;
  }
}
.icon-appointment {
  &:before {
    content: $icon-appointment;
  }
}
.icon-call-assistance {
  &:before {
    content: $icon-call-assistance;
  }
}
.icon-group-people {
  &:before {
    content: $icon-group-people;
  }
}
.icon-hotel-transfer {
  &:before {
    content: $icon-hotel-transfer;
  }
}
.icon-med-assurance {
  &:before {
    content: $icon-med-assurance;
  }
}
.icon-shield-thin {
  &:before {
    content: $icon-shield-thin;
  }
}
.icon-user-info {
  &:before {
    content: $icon-user-info;
  }
}
.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
.icon-facebook-square {
  &:before {
    content: $icon-facebook-square;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-airplane {
  &:before {
    content: $icon-airplane;
  }
}
.icon-no-plane {
  &:before {
    content: $icon-no-plane;
  }
}
.icon-pin-shape-thin {
  &:before {
    content: $icon-pin-shape-thin;
  }
}
.icon-pins-shape-thin {
  &:before {
    content: $icon-pins-shape-thin;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-thick-left {
  &:before {
    content: $icon-arrow-thick-left;
  }
}
.icon-arrow-thick-right {
  &:before {
    content: $icon-arrow-thick-right;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-champagne {
  &:before {
    content: $icon-champagne;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-clock-simple {
  &:before {
    content: $icon-clock-simple;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-event {
  &:before {
    content: $icon-event;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-fire {
  &:before {
    content: $icon-fire;
  }
}
.icon-gallery {
  &:before {
    content: $icon-gallery;
  }
}
.icon-gastronomia {
  &:before {
    content: $icon-gastronomia;
  }
}
.icon-golf {
  &:before {
    content: $icon-golf;
  }
}
.icon-group-lg {
  &:before {
    content: $icon-group-lg;
  }
}
.icon-help {
  &:before {
    content: $icon-help;
  }
}
.icon-key {
  &:before {
    content: $icon-key;
  }
}
.icon-landing {
  &:before {
    content: $icon-landing;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-love {
  &:before {
    content: $icon-love;
  }
}
.icon-minus-rounded {
  &:before {
    content: $icon-minus-rounded;
  }
}
.icon-palm-tree {
  &:before {
    content: $icon-palm-tree;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-pin-full {
  &:before {
    content: $icon-pin-full;
  }
}
.icon-pin {
  &:before {
    content: $icon-pin;
  }
}
.icon-pins {
  &:before {
    content: $icon-pins;
  }
}
.icon-plus-rounded {
  &:before {
    content: $icon-plus-rounded;
  }
}
.icon-sagre {
  &:before {
    content: $icon-sagre;
  }
}
.icon-sea {
  &:before {
    content: $icon-sea;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-shield-pro {
  &:before {
    content: $icon-shield-pro;
  }
}
.icon-shield {
  &:before {
    content: $icon-shield;
  }
}
.icon-sport-adventure {
  &:before {
    content: $icon-sport-adventure;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-take-off {
  &:before {
    content: $icon-take-off;
  }
}
.icon-temptation {
  &:before {
    content: $icon-temptation;
  }
}
.icon-treatment {
  &:before {
    content: $icon-treatment;
  }
}
.icon-value {
  &:before {
    content: $icon-value;
  }
}
.icon-wellness {
  &:before {
    content: $icon-wellness;
  }
}
.icon-wheat {
  &:before {
    content: $icon-wheat;
  }
}
.icon-hours {
  &:before {
    content: $icon-hours;
  }
}
.icon-free-canc {
  &:before {
    content: $icon-free-canc;
  }
}
.icon-guide {
  &:before {
    content: $icon-guide;
  }
}
.icon-insurance {
  &:before {
    content: $icon-insurance;
  }
}
.icon-luggage {
  &:before {
    content: $icon-luggage;
  }
}
.icon-transfer {
  &:before {
    content: $icon-transfer;
  }
}
.icon-virus {
  &:before {
    content: $icon-virus;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}
.icon-alert {
  &:before {
    content: $icon-alert;
  }
}
.icon-catalogue {
  &:before {
    content: $icon-catalogue;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-contract {
  &:before {
    content: $icon-contract;
  }
}
.icon-cruise {
  &:before {
    content: $icon-cruise;
  }
}
.icon-date {
  &:before {
    content: $icon-date;
  }
}
.icon-directions {
  &:before {
    content: $icon-directions;
  }
}
.icon-double-bed {
  &:before {
    content: $icon-double-bed;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-fax {
  &:before {
    content: $icon-fax;
  }
}
.icon-file {
  &:before {
    content: $icon-file;
  }
}
.icon-group {
  &:before {
    content: $icon-group;
  }
}
.icon-heart {
  &:before {
    content: $icon-heart;
  }
}
.icon-id-card {
  &:before {
    content: $icon-id-card;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-instagram-shape {
  &:before {
    content: $icon-instagram-shape;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-logout {
  &:before {
    content: $icon-logout;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-phone-shape {
  &:before {
    content: $icon-phone-shape;
  }
}
.icon-pin-shape {
  &:before {
    content: $icon-pin-shape;
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}
.icon-plane {
  &:before {
    content: $icon-plane;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-poster {
  &:before {
    content: $icon-poster;
  }
}
.icon-print {
  &:before {
    content: $icon-print;
  }
}
.icon-question {
  &:before {
    content: $icon-question;
  }
}
.icon-quote {
  &:before {
    content: $icon-quote;
  }
}
.icon-report {
  &:before {
    content: $icon-report;
  }
}
.icon-reset {
  &:before {
    content: $icon-reset;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-service {
  &:before {
    content: $icon-service;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-single-bed {
  &:before {
    content: $icon-single-bed;
  }
}
.icon-tick {
  &:before {
    content: $icon-tick;
  }
}
.icon-ticket {
  &:before {
    content: $icon-ticket;
  }
}
.icon-time {
  &:before {
    content: $icon-time;
  }
}
.icon-tour {
  &:before {
    content: $icon-tour;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-url {
  &:before {
    content: $icon-url;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-view {
  &:before {
    content: $icon-view;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
