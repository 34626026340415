.u-text {
  &--center {
    text-align: center !important;
  }
  &--right {
    text-align: right !important;
  }
  &--left {
    text-align: left !important;
  }
  &--light {
    font-weight: map-get($font-weights, light) !important;
  }
  &--regular {
    font-weight: map-get($font-weights, regular) !important;
  }
  &--medium {
    font-weight: map-get($font-weights, medium) !important;
  }
  &--bold {
    font-weight: map-get($font-weights, bold) !important;
  }
  &--lh-1-25 {
    line-height: 1.25 !important;
  }
  &--lh-1-5 {
    line-height: 1.5 !important;
  }
  &--lh-1-6 {
    line-height: 1.6 !important;
  }
  &--underlined {
    text-decoration: underline;
  }
  &--link {
    cursor: pointer;
    text-decoration: underline;
    color: setColor('semantic', 'info') !important;
    &:hover {
        color: setColor('semantic', 'info');
        text-decoration: underline;
      }
    small {
      color: setColor('semantic', 'info') !important;
    }
  }
  &--ellipsis-1 {
    @include multiLineEllipsis($lineHeight: 1.5rem, $lineCount: 1, $bgColor: transparent);
  }
  &--ellipsis-2 {
    @include multiLineEllipsis($lineHeight: 1.5rem, $lineCount: 2, $bgColor: transparent);
  }
  &--ellipsis-3 {
    @include multiLineEllipsis($lineHeight: 1.5rem, $lineCount: 3, $bgColor: transparent);
  }
  &--terms {
    color: setColor('dark', 'darken-80');
    font-size: 12px;
    line-height: 1rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}
