/* _form-group-custom.scss */
.form-group-custom {
  position: relative;
  margin-bottom: 1rem; /* 16px */
  &__label {
    display: block;
    font-size: 1rem; /* 16px */
    color: $text-color-primary;
    letter-spacing: .03rem;
    margin-bottom: 0.435rem; /* 7px */
    @media (min-width: 997px) {
      font-size: 0.875rem; /* 14px */
    }
    span {
      display: block;
      font-size: .675rem;
      letter-spacing: 0;
      margin-top: .3rem;
    }
    &.color-light {
      color: $ui-color-05;
    }
    &.color-white {
      color: #fff;
    }
    &.color-dark {
      color: #000;
    }
  }
  &__caption {
    display: block;
    margin-top: 0.25rem;
    width: 85%;
    color: $text-color-secondary;
    font-style: italic;
    font-size: 0.75rem; /* 12px */
    &.invalid,
    &.is-invalid {
      color: $color-danger;
    }
    &.valid,
    &.is-valid {
      color: $color-success;
    }
    &.warning,
    &.is-warning {
      color: $color-warning;
    }
  }
}
/* _form-group-custom.scss END */
