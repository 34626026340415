/* _form-ng.scss */
.form-ng {
  &-select {
    &.ng-select {
      border: 0;
      .ng-clear-wrapper {
        right: 0;
        position: absolute;
        width: 20px;
        .ng-clear {
          font-size: 1.3rem;
        }
      }
      .ng-arrow-wrapper {
        visibility: hidden;
      }
      .ng-select-container {
        font-size: .875rem;
        border-radius: .25rem;
        border: .0625rem solid #ced4da;
        height: calc(1.5em + .93rem + 2px);
      }
    }
  }
}
/* _form-ng.scss END */
