@import "../variables.scss";

@mixin booking() {
  .booking-summery {
    .recap {
      background-color: #fff;
      border-radius: 0;
      font-family: "Montserrat", sans-serif;
      color: #000;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      outline: 2px solid $wtg-brand-color-01;
      &__heading {
        color: #000;
      }
      &__journey {
        font-size: 0.9125rem;
      }
      &__charge {
        &__list {
          font-size: 0.9125rem;
        }
        &__price {
          font-size: 1.125rem;
          font-weight: 600;
        }
      }
      &__cta {
        .btn {
          border-radius: 0 !important;
        }
      }
      &__bottom-line,
      &__loader__text {
        font-size: 0.9125rem;
      }
      &__loader {
        &__circle {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          position: relative;
          display: block;
          animation: rotate 1s linear infinite;
          margin: 1rem auto;
          &:before {
            content: "";
            box-sizing: border-box;
            position: absolute;
            inset: 0;
            border-radius: 50%;
            border: 5px solid $wtg-brand-color-01;
            animation: prixClipFix 2s linear infinite;
          }

          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }

          @keyframes prixClipFix {
            0% {
              clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
            }
            25% {
              clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
            }
            50% {
              clip-path: polygon(
                50% 50%,
                0 0,
                100% 0,
                100% 100%,
                100% 100%,
                100% 100%
              );
            }
            75% {
              clip-path: polygon(
                50% 50%,
                0 0,
                100% 0,
                100% 100%,
                0 100%,
                0 100%
              );
            }
            100% {
              clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
            }
          }
        }
      }
      &__holiday {
        &__category {
          font-size: 1.2rem;
          + i {
            font-size: 1rem;
            vertical-align: middle;
          }
        }
      }
      &__accordion__toggle {
        color: $wtg-brand-color-03;
      }
    }
  }
  .booking-flow {
    &__container {
      .room-slide {
        border-radius: 0;
        &__footer {
          .btn {
            background-color: $wtg-brand-color-01;
            border-color: $wtg-brand-color-01;
          }
        }
      }
    }
    &__content {
      h4 {
        &.booking-flow__section-title {
          font-size: 1.125rem;
          color: $wtg-brand-color-02;
          font-weight: 700;
          line-height: 1;
          margin-bottom: 0;
          border-bottom: 0;
          + .booking-flow__section-description {
            font-size: 0.9125rem;
          }
          .booking-flow__current-selection {
            display: inline-block;
            margin-left: 1rem;
            color: $wtg-brand-color-02;
            font-weight: 600;
          }
        }
      }
    }
    &__wrapper {
      padding: 1rem 0;
      @media (min-width: 992px) {
        padding: 1rem 2rem;
      }
      .service-card {
        &__single {
          border-radius: 0;
          margin-bottom: 1rem;
          font-family: "Montserrat", sans-serif;
        }
        &__description {
          font-weight: 600;
        }
        &__quantity,
        &__guests,
        &__price {
          > span {
            font-size: 0.75rem;
            opacity: 0.7;
            display: inline-block;
            margin-bottom: 0.15rem;
          }
        }
        &__name {
          font-size: 1rem;
        }
        &__select {
          .price {
            font-size: 1.2rem;
          }
        }
      }
      .card-accomodation {
        .booking-card-accomodation {
          &__container {
            border-radius: 0;
          }
        }
      }
      .occupancy__inner {
        border-radius: 0;
      }
      .flight {
        &__box {
          .custom-radio-card__container {
            border-radius: 0;
          }
        }
        &__choice {
          &__container {
            flex-direction: column;
            border-radius: 0;
            @media (min-width: 992px) {
              flex-direction: row;
            }
          }
          &__item {
            flex-direction: column;
            min-height: unset;
          }
          &__label {
            flex: auto;
            margin-bottom: 0.5rem;
          }
        }
      }
      .row-icon {
        .row-icon {
          &__icon {
            color: $wtg-brand-color-01;
          }
        }
      }
      .guest__icon {
        color: $wtg-brand-color-01;
      }
    }
    &__header {
      &__title {
        font-size: 1.5rem;
        color: $wtg-brand-color-01;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
      }
      &__description {
        font-size: 0.875rem;
        line-height: 1.4;
      }
    }
    &__button {
      &--enabled {
        background-color: $wtg-brand-color-01;
        border-color: $wtg-brand-color-01;
      }
    }
    &__form {
      &__section {
        border-radius: 0 !important;
        // select.form-control {
        //   height: calc(1.2em + 0.93rem + 2px);
        //   border: 0.0625rem solid #ced4da;
        //   border-radius: 4px;
        // }
      }
      &__title {
        font-size: 1.125rem;
        small {
          display: inline-block;
          margin-left: 0.35rem;
        }
      }
    }
    &__cta-wrapper {
      .booking-flow__wrapper {
        text-align: center;
      }
    }
    &__section {
      &-title {
        font-size: 1.35rem;
        color: $wtg-brand-color-01;
      }
    }
  }
  .container-cruise-booking {
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
    @media (min-width: 1440px) {
      max-width: 1320px;
    }
  }
  .custom-select {
    border-radius: 4px !important;
  }
  .wrapper-page {
    .detail-receipt {
      h1 {
        font-size: 1.85rem !important;
      }
      &__title {
        font-family: "Montserrat", sans-serif !important;
      }
    }
    .detail-receipt {
      &__icon {
        background-color: $wtg-brand-color-01 !important;
      }

      &__airport {
        > span {
          font-family: "Montserrat", sans-serif !important;
          i {
            color: $wtg-brand-color-01 !important;
          }
          > span {
            font-family: "Montserrat", sans-serif !important;
          }
        }
      }
      &__title {
        font-family: "Montserrat", sans-serif !important;
      }
      &__list-price {
        h5 {
          color: $wtg-brand-color-01 !important;
          font-family: "Montserrat", sans-serif !important;
        }
      }
      &__line {
        &--brand-color {
          span {
            + span {
              color: $wtg-brand-color-02 !important;
            }
          }
        }
        span {
          font-family: "Montserrat", sans-serif !important;
        }
        &--bold {
          span {
            font-family: "Montserrat", sans-serif !important;
            + span {
              color: $wtg-brand-color-01 !important;
            }
          }
        }
      }
    }

    .c-feature-list {
      li {
        font-family: "Montserrat", sans-serif !important;
        > span {
          a {
            color: $wtg-brand-color-01 !important;
          }
        }
      }
      &__with-icon {
        &--brand {
          i {
            color: $wtg-brand-color-01 !important;
          }
        }
      }
    }
  }

  .information-box {
    font-family: "Montserrat", sans-serif;
    h6 {
      color: $wtg-brand-color-01;
    }
  }

  .booking-steps--full-width {
    margin: 0 auto;
    .page {
      background: transparent;
    }
  }
}
