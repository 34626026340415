.engine {
  position: relative;
  z-index: 2;
  border-radius: 10px;
  margin-bottom: 2rem;
  // padding: 0.4rem 0 0;
  &.top {
    @media (min-width: 992px) {
      z-index: 1060;
    }
  }

  &-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 0.6rem 1rem 1rem;
    position: relative;
    z-index: 2;
    &.top {
      @media (min-width: 992px) {
        z-index: 1060;
      }
    }
  }
  &__more-options {
    text-align: right;
    padding: 0 3rem 1rem;
  }
  .engine {
    &__tab-content {
      padding: 1rem 2rem;
    }
    &-tabs {
      background-color: transparent;
    }
    &-body {
      background-color: $engine-bg;
      border-radius: 24px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      @media (min-width: 992px) {
        border-radius: 24px;
      }
    }
    &__nav-tabs {
      border: 0;
      padding: 0;
      @media (min-width: 992px) {
        padding: 0 1rem 0;
      }
    }
    &-tabs {
      .engine__nav-link {
        font-size: 1.4rem;
        position: relative;
        text-align: center;
        border: 0;
        padding: 0.5rem 1rem;
        margin: 0 0.5rem;
        font-family: Inter, sans-serif;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        flex-flow: column;
        align-items: center;
        gap: 0.5rem;
        white-space: nowrap;
        min-width: 105px;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;

        span {
          color: $brand-color-02;
          font-size: 0.95rem;
          display: block;
        }
        i {
          font-size: 1.4rem;
          color: $brand-color-02;
        }
        &.active {
          font-weight: 700;
          border-color: $engine-bg;
          background-color: $engine-bg;
          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid #ddedfd;
          }
          span {
            color: $brand-color-01;
          }
          i {
            color: $brand-color-01;
          }
        }
      }
    }
  }
  .search-form {
    &__engine {
      border: none;
      background: transparent;
      @media (min-width: 992px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        flex-wrap: wrap;
      }
      &--row {
        @media (min-width: 992px) {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
        }
        &__background {
          background-color: #fff;
          margin-top: 0.5rem;
          border-radius: 8px;
          padding-top: 0.5rem;
        }
      }
      &--two-rows {
        flex-direction: column;
      }
      .form-group-custom {
        margin-bottom: 0;
        label {
          padding-left: 2rem;
          margin-bottom: 0;
          font-family: Inter, sans-serif;
          font-size: 0.875rem;
          color: #888;
        }
        i {
          color: $brand-color-02;
          font-size: 1.1rem;
          &.icon-plane {
            font-size: 1.3rem;
            margin-left: -5px;
          }
          &:before {
            font-weight: 600;
          }
        }
        .close-element {
          i {
            color: $ui-color-04;
            font-size: 0.65rem;
          }
        }
      }
      .form-control-custom {
        background-color: transparent;
        border: 0;
        font-size: 1rem;
      }
      input {
        &::placeholder {
          color: #000;
        }
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }
    &__row {
      flex-direction: column;
      @media (min-width: 992px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        &:not(:last-child) {
          border-bottom: 1px solid #c1c1c1;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
        }
      }
      .search-form__element:last-of-type {
        border-bottom: 1px solid #888;
        @media (min-width: 992px) {
          border-bottom: 0;
        }
      }
    }
    &__col {
      flex: 1 1 0;
    }
    &__cta {
      flex: 0 0 100%;
      @media (min-width: 992px) {
        flex: 0 0 150px;
      }
    }
    &__element {
      border-bottom: 1px solid #888;
      @media (min-width: 992px) {
        border-bottom: 0;
      }
      &:first-child {
        @media (min-width: 992px) {
          padding-left: 0;
        }
      }
      &:not(.btn) {
        border-radius: 0;
      }
      &:not(:last-of-type) {
        @media (min-width: 992px) {
          border-right: 2px solid #888;
          border-bottom: 0;
        }
      }
      &[disabled],
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &__select {
      .ng-select {
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }
    &__calendar {
      .form-group-custom {
        border: 0;
      }
    }
  }
}
