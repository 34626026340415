@import "src/styles/refactoring/configurations";
@import "../variables.scss";

@mixin detail() {
  .detail-header {
    &__logo__inner {
      border-radius: 0 !important;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08);
    }
    &__stars {
      font-size: 1.4rem;
      i {
        font-size: 1.3rem !important;
      }
    }
  }
  .page-gallery {
    .c-mosaik {
      &__item {
        &:before {
          content: none;
        }
        img {
          border-radius: 0 !important;
        }
      }
      &__cta {
        border-radius: 0;
      }
    }
  }
  .detail-tabs {
    .u-tabs {
      .u-tabs {
        &__item {
          background-color: #e9e9e9;
          text-align: center;
          margin-right: 1rem;
          padding: 0 !important;
          &--special {
            .u-tabs__link {
              background-color: unset;
              color: #888;
              border-color: transparent;
            }
          }
          &.swiper-slide-active {
            background-color: $wtg-brand-color-02;
            .u-tabs__link {
              color: #fff;
            }
          }
        }
        &__link {
          font-family: "Montserrat", sans-serif;
          color: #888;
          font-weight: 400;
          text-transform: uppercase;
          border-radius: 0 !important;
          padding: 1rem !important;
          min-width: 170px;
          &.active {
            background-color: $wtg-brand-color-02;
            color: #fff;
          }
        }
      }
      .swiper.s-wrapper {
        .swiper-button-prev,
        .swiper-button-next {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .detail-container {
    .wrapper-page {
      &__aside {
        padding-left: 2rem;
        padding-right: 0;
      }
    }
  }
  .detail-recap {
    &__container {
      .detail-recap {
        border-radius: 0;
      }
    }
    &__header {
      padding: 1rem !important;
      flex-direction: column !important;
    }
    &__occupancy {
      margin: 0 0 0.5rem !important;
      line-height: 1;
    }
    &__price {
      font-weight: 600 !important;
      display: block;
      font-size: 26px !important;
      color: $wtg-brand-color-01;
      font-family: "Montserrat", sans-serif;
      line-height: 1 !important;
      span {
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }
    &__guests {
      display: block;
      font-size: 11px;
      color: #8a8a8a;
      font-family: "Montserrat", sans-serif;
    }
    &__body {
      background-color: #fff !important;
    }
    &__text {
      font-family: "Montserrat", sans-serif;
      font-size: 0.875rem;
    }
    &__icon {
      color: $wtg-brand-color-01;
    }
    &__see-options {
      display: none !important;
    }
    &__change-guests {
      display: block !important;
      text-decoration: underline;
      font-weight: 600;
      font-size: 0.7125rem;
      color: $wtg-brand-color-03;
      cursor: pointer;
      font-family: "Montserrat", sans-serif;
    }
    &__cta {
      &__button {
        border-radius: 0 !important;
      }
    }
  }
  .detail-steps {
    &__description {
      line-height: 25px;
      &.detail-steps__text {
        p {
          margin-bottom: 1.75rem;
          line-height: 1.5;
          strong {
            //font-size: 1.125rem;
            display: inline-block;
            margin-bottom: 0.35rem;
          }
        }
      }
    }
  }
  .detail-section {
    h4.detail-section__title {
      font-weight: 500;
      color: $wtg-brand-color-01;
      font-size: 1.25rem;
    }
    .detail-content {
      &__section {
        h5.detail-steps__title {
          font-weight: 500;
          color: $wtg-brand-color-01;
          font-size: 1.25rem;
        }
      }
      &__itinerary {
        border-radius: 0;
        .detail-steps {
          .swiper {
            &.s-wrapper {
              .swiper-container-vertical {
                .swiper-button-next {
                }
              }
            }
          }
        }
      }
      &__single-step {
        &--harbour {
          color: $wtg-brand-color-02;
        }
      }
    }
  }
  .accomodation-tab {
    &__occupancy {
      display: none;
    }
  }
  .c-detail-price-grid {
    &__airports,
    &__cabins {
      &-item {
        span {
          font-family: "Montserrat", sans-serif !important;
        }
      }
    }
    &__dates {
      .range-dates {
        font-family: "Montserrat", sans-serif !important;
      }
    }
    &__price-item {
      &.active {
        background-color: $wtg-brand-color-01 !important;
      }
    }
    &__departing,
    &__price {
      font-family: "Montserrat", sans-serif !important;
      small {
        font-family: "Montserrat", sans-serif !important;
      }
    }
  }
  .detail-cta {
    display: none !important;
  }
  .c-detail-steps-solution {
    .c-detail-steps-solution {
      &__months,
      &__nights {
        span {
          cursor: pointer;
          &.active {
            background-color: $wtg-brand-color-01 !important;
          }
        }
      }
    }
  }
  .c-card-booking {
    &__cta {
      .btn {
        width: 100%;
        font-weight: 600;
      }
    }
  }
  .e-icons-wrap {
    &__icon {
      font-family: "Montserrat", sans-serif;
    }
  }
  .cruise-tab__excursions {
    .card-excursion {
      border-radius: 0;
      &__image {
        height: 165px;
      }
      &__title {
        margin: 0.35rem 0 0.25rem;
        color: $wtg-brand-color-02;
        text-transform: capitalize;
      }
      &__cod {
        font-size: 0.8125rem;
      }
      &__content {
        padding: 0.5rem 0.75rem;
      }
      &__bottom {
        font-family: "Montserrat", sans-serif;
        opacity: 0.6;
        font-size: 0.8125rem;
        span {
          display: inline-block;
          margin-top: 0.25rem;
        }
      }
      &__detail {
        font-family: "Montserrat", sans-serif;
        line-height: 1.4;
      }
    }
  }
  .inline-select {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    &__label {
      flex: auto;
      width: 100%;
      margin-bottom: 0.5rem;
      @media (min-width: map-get($grid-breakpoints, md)) {
        max-width: 200px;
      }
    }
    &__select {
      flex: auto;
      width: 100%;
      @media (min-width: map-get($grid-breakpoints, md)) {
        max-width: 300px;
      }
    }
  }
  .card-accomodation {
    .card-accomodation {
      &__cabin-price,
      &__cta {
        font-family: "Montserrat", sans-serif;
      }
      &__cabin-price {
        span {
          color: $wtg-brand-color-02;
        }
      }
      &__room {
        .o-room-solution {
          border-radius: 0;
          &__header {
            h6 {
              color: $wtg-brand-color-03;
            }
            input[type="radio"] {
              //background-color: #fff;
              margin: 0;
              font: inherit;
              //color: $wtg-brand-color-01;
              left: -0.35rem;
              width: 1.15em;
              height: 1.15em;
              //border: 0.15em solid $wtg-brand-color-01;
              border-radius: 50%;
              &:checked {
                background-color: $wtg-brand-color-01;
                border-color: $wtg-brand-color-01;
              }
            }
          }
          &__body {
            margin-bottom: 0;
            .u-list {
              &--fare {
                font-family: "Montserrat", sans-serif;
              }
            }
          }
        }
        &.o-room__more {
          background-color: $wtg-brand-color-04;
        }
      }
    }
  }
  .map,
  .map-detail {
    border-radius: 0 !important;
    ul {
      li {
        line-height: 1.5;
        font-family: "Montserrat", sans-serif;
        font-size: 0.9125rem;
      }
    }
  }
  .detail-steps
    .swiper.s-wrapper.swiper-container-vertical
    > .swiper-button-prev::after,
  .detail-steps
    .swiper.s-wrapper.swiper-container-vertical
    > .swiper-button-next::after {
    color: $wtg-brand-color-02 !important;
  }
  .card-accomodation {
    &__cabin-price {
      span {
        color: $wtg-brand-color-01 !important;
      }
    }
    &__content {
      h6 {
        color: $wtg-brand-color-02 !important;
      }
    }
  }
  .ship__detail-single__value {
    color: $wtg-brand-color-02 !important;
  }
}
