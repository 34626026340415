@import "../variables.scss";

@mixin news() {
  .c-news {
    .c-news {
      &__all-news {
        .c-news__card {
          border-radius: 0;
          &:hover {
            box-shadow: 0 0 0 2px $wtg-brand-color-01;
          }
        }
      }
      &__title {
        color: $wtg-brand-color-01;
      }
    }
  }

  .ui-container--template {
    .ui-grid--card-container {
      .e-title__with-divider {
        hr {
          display: none;
        }
      }
    }
  }
  .ui-post {
    .c-inner-post {
      //text-align: center;
      .e-title {
        text-align: center;
        &__with-divider {
          hr {
            display: none;
          }
        }
      }
    }
  }
}

