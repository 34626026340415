@import "../variables.scss";

@mixin bookingSteps() {
  .booking-steps {
    background-color: $wtg-brand-color-04;
  }
  swiper {
    &.step {
      &__list {
        .swiper {
          &.s-wrapper {
            .swiper-slide {
              h4.step__name {
                font-size: .95rem;
                letter-spacing: .0125rem;
                font-weight: 500;
                opacity: .5;
                &--active {
                  font-weight: 700;
                  opacity: 1;
                  color: #000;
                }
              }
              &-done {
                h4.step__name {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}
