/* _form-check.scss */
.form-check-custom {
  display: flex;
  align-items: center;
  // height: $check-box-dimention;
  position: relative;
  padding-left: calc(#{$check-box-dimention} + 0.625rem);
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 0.88rem;
  line-height: 1.5;
  user-select: none;

  &.disabled,
  &.is-disabled {
    opacity: 0.4;
    pointer-events: none;
    .form-check-custom {
      &__check,
      &__radio {
        opacity: 0.8;
        pointer-events: none;
        background-color: transparent !important;
        border-color: $ui-color-04 !important;
      }
    }
    input {
      &:checked {
        ~ .form-check-custom {
          &__check,
          &__radio {
            border-color: $btn-bg-light-blue-01;
            &:after {
              border-color: $btn-bg-light-blue-01;
            }
          }
          &__radio {
            &:after {
              background-color: $brand-color-01;
            }
          }
        }
      }
    }
  }

  &.invalid,
  &.is-invalid {
    color: $color-danger;
    .form-check-custom {
      &__check,
      &__radio {
        border-color: $color-danger;
      }
    }
    input {
      &:checked {
        ~ .form-check-custom {
          &__check,
          &__radio {
            border-color: $color-danger;
            &:after {
              border-color: $color-danger;
            }
          }
          &__radio {
            &:after {
              background-color: $color-danger;
            }
          }
        }
      }
    }
  }

  &.valid,
  &.is-valid {
    color: $color-success;
    .form-check-custom {
      &__check,
      &__radio {
        border-color: $color-success;
      }
    }
    input {
      &:checked {
        ~ .form-check-custom {
          &__check,
          &__radio {
            border-color: $color-success;
            &:after {
              border-color: $color-success;
            }
          }
          &__radio {
            &:after {
              background-color: $color-success;
            }
          }
        }
      }
    }
  }

  &.warning,
  &.is-warning {
    color: $color-warning;
    .form-check-custom {
      &__check,
      &__radio {
        border-color: $color-warning;
      }
    }
    input {
      &:checked {
        ~ .form-check-custom {
          &__check,
          &__radio {
            border-color: $color-warning;
            &:after {
              border-color: $color-warning;
            }
          }
          &__radio {
            &:after {
              background-color: $color-warning;
            }
          }
        }
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ .form-check-custom {
        &__check,
        &__radio {
          background-color: $brand-color-01;
          border: 1px solid $btn-bg-light-blue-01;
          &:after {
            display: block;
          }
        }
      }
    }
  }

  &__check {
    position: absolute;
    top: 0;
    left: 0;
    height: $check-box-dimention;
    width: $check-box-dimention;
    background-color: #fff;
    border: 1px solid #888;
    border-radius: $input-border-radius;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 32%;
      top: 16%;
      width: calc(#{$check-box-dimention} / 4);
      height: calc(#{$check-box-dimention} / 2);
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &__radio {
    position: absolute;
    top: 0;
    left: -23px;
    height: $check-box-dimention;
    width: $check-box-dimention;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid $btn-bg-light-blue-01;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 14%;
      left: 14%;
      width: calc(#{$check-box-dimention} / 1.5);
      height: calc(#{$check-box-dimention} / 1.5);
      border-radius: calc(#{$check-box-dimention} / 1.5);
      background: $color-success;
    }
  }
  &__label {
    position: relative;
    margin-right: 1rem;
  }
  &__logo {
    position: absolute;
    right: .5rem;
    width: 2rem;
  }
}

.form-check-big {
  height: auto;
  padding: 0;
  &__label {
    font-size: 1.375rem;
    color: $brand-color-03;
    font-weight: 700;
    background-color: #EBF9FF;
    border-radius: 5px;
    padding: 20px 60px 20px;
    position: relative;
    cursor: pointer;
    width: 100%;
  }
  &__price {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    font-size: 1.5rem;
    color: $brand-color-03;
    font-weight: 700;
    span {
      font-size: 1rem;
      color: #000;
      font-weight: 400;
    }
  }
  &__caption {
    font-size: .875rem;
    line-height: 1.7;
    color: #000;
  }
  &__radio {
    top: 1.5rem;
    left: 1.5rem;
  }
}

.form-check {
  &--custom {
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #7b7b7b;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: $brand-color-01;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
/* _form-check.scss END */
