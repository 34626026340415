@font-face {
  font-family: 'icons-tags';
  src:
    url('../assets/fonts/icons-tags.ttf?nd5nhl') format('truetype'),
    url('../assets/fonts/icons-tags.woff?nd5nhl') format('woff'),
    url('../assets/fonts/icons-tags.svg?nd5nhl#icons-tags') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="t-icon-"], [class*=" t-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons-tags' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.t-icon-bycicle-e:before {
  content: "\e931";
}
.t-icon-golf:before {
  content: "\e932";
}
.t-icon-pool-building:before {
  content: "\e933";
}
.t-icon-spa:before {
  content: "\e934";
}
.t-icon-winery:before {
  content: "\e935";
}
.t-icon-nightlife:before {
  content: "\e92e";
}
.t-icon-show:before {
  content: "\e92f";
}
.t-icon-tour:before {
  content: "\e930";
}
.t-icon-mountain:before {
  content: "\e92d";
}
.t-icon-archer:before {
  content: "\e929";
}
.t-icon-ball-basketball:before {
  content: "\e92a";
}
.t-icon-ball-beach-volley:before {
  content: "\e92b";
}
.t-icon-cooking-class:before {
  content: "\e92c";
}
.t-icon-culture:before {
  content: "\e924";
}
.t-icon-food-and-wine:before {
  content: "\e925";
}
.t-icon-nature:before {
  content: "\e926";
}
.t-icon-sea:before {
  content: "\e927";
}
.t-icon-sport:before {
  content: "\e928";
}
.t-icon-breakfast:before {
  content: "\e921";
}
.t-icon-dinner:before {
  content: "\e922";
}
.t-icon-lunch:before {
  content: "\e923";
}
.t-icon-ball-soccer:before {
  content: "\e900";
}
.t-icon-ball-tennis:before {
  content: "\e901";
}
.t-icon-clown:before {
  content: "\e902";
}
.t-icon-fitness:before {
  content: "\e903";
}
.t-icon-horse:before {
  content: "\e904";
}
.t-icon-kite:before {
  content: "\e905";
}
.t-icon-playa:before {
  content: "\e906";
}
.t-icon-pool-kids:before {
  content: "\e907";
}
.t-icon-pool:before {
  content: "\e908";
}
.t-icon-sailing-boat:before {
  content: "\e909";
}
.t-icon-sauna:before {
  content: "\e90a";
}
.t-icon-wind-surf:before {
  content: "\e90b";
}
.t-icon-adventure:before {
  content: "\e90c";
}
.t-icon-bambini:before {
  content: "\e90d";
}
.t-icon-business:before {
  content: "\e90e";
}
.t-icon-bycicle:before {
  content: "\e90f";
}
.t-icon-coppia:before {
  content: "\e910";
}
.t-icon-cruise:before {
  content: "\e911";
}
.t-icon-design:before {
  content: "\e912";
}
.t-icon-diving:before {
  content: "\e913";
}
.t-icon-famiglia:before {
  content: "\e914";
}
.t-icon-gourmet:before {
  content: "\e915";
}
.t-icon-gruppi:before {
  content: "\e916";
}
.t-icon-historical:before {
  content: "\e917";
}
.t-icon-individual:before {
  content: "\e918";
}
.t-icon-motorcycle:before {
  content: "\e919";
}
.t-icon-photographic:before {
  content: "\e91a";
}
.t-icon-religious:before {
  content: "\e91b";
}
.t-icon-romantic:before {
  content: "\e91c";
}
.t-icon-safari:before {
  content: "\e91d";
}
.t-icon-steering:before {
  content: "\e91e";
}
.t-icon-trekking:before {
  content: "\e91f";
}
.t-icon-yoga:before {
  content: "\e920";
}
