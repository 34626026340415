.u-scrollbar {
  //Firefox
  scrollbar-color: setColor('dark', 'darken-20') setColor('light', 'lighten-100');
  scrollbar-width: none; //thin;
  transition: scrollbar-color .33s ease-out;

  &::-webkit-scrollbar {
    scrollbar-width: none; //thin;
    width: 0.5em;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    background: setColor('light', 'lighten-30');
    border-radius: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background: setColor('dark', 'darken-20');
    border-radius: 0.5em;
    transition: background .22s ease-in;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: setColor('dark', 'darken-60');
    scrollbar-color: setColor('dark', 'darken-60') setColor('light', 'lighten-40');
    transition: background .22s ease-in, scrollbar-color .22s ease-in;
  }
}
