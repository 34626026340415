@import "colors.scss";

// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Spacing and Margin
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.5,
  ),
  //8px
  2: $spacer,
  //16px
  3:
    (
      $spacer * 1.5,
    ),
  //24px
  4:
    (
      $spacer * 2,
    ),
  //32px
  5:
    (
      $spacer * 2.5,
    ),
  //40px
  6:
    (
      $spacer * 3.75,
    ),
  //60px
  7:
    (
      $spacer * 7.5,
    )
    //120px,,
);
// Usage: map-get($spacers, 2);

// Font
$font-primary: "Roboto Slab", sans-serif;
$font-secondary: "Open Sans", sans-serif;
$font-weights: (
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
);
// Usage: map-get($font-weights, bold);

// Buttons configurations
$btn: (
  font-size: 1rem,
  font-weight: 700,
  border-width: 0.125rem,
  border-radius: 0.875rem,
  hover-ring: 0 0 0 0.125rem,
  focus-ring: 0 0 0 0.0625rem,
);
// Usage: map-get($btn, font-size);

// Cards configurations
$card: (
  border-radius: 0.75rem,
  body-padding: 1.5rem,
  pic-shadow:
    linear-gradient(
      to bottom,
      setColor("dark", "darken-100"),
      setColor("dark", "darken-0")
    ),
  box-shadow: (
    0 5px 20px 0 rgba(0, 0, 0, 0.1),
    0 0 5px 0 rgba(0, 0, 0, 0.15),
  ),
);
// Usage: map-get($card, border-radius);

// Shadows and Elevations
$shadow: (
  1: (
    0 1px 1px 0 rgba(171, 147, 67, 0.15),
    0 0 1px 0 rgba(171, 147, 67, 0.2),
  ),
  2: (
    0 4px 8px -2px rgba(171, 147, 67, 0.15),
    0 0 1px 0 rgba(171, 147, 67, 0.2),
  ),
  3: (
    0 8px 16px -4px rgba(171, 147, 67, 0.15),
    0 0 1px 0 rgba(171, 147, 67, 0.2),
  ),
  4: (
    0 12px 24px -6px rgba(171, 147, 67, 0.15),
    0 0 1px 0 rgba(171, 147, 67, 0.2),
  ),
  5: (
    0 20px 32px -8px rgba(171, 147, 67, 0.15),
    0 0 1px 0 rgba(171, 147, 67, 0.2),
  ),
);
// Usage: map-get($shadow, 3);

$page: (
  header-height: 4rem,
);

// Base Tune
$font-size-base: 1rem;
$line-height-base: 1.2;

// Colors
$primary: setColor("primary", "p01");
$success: setColor("semantic", "success");
$info: setColor("semantic", "info");
$warning: setColor("semantic", "warning");
$danger: setColor("semantic", "danger");

// Input
$input-border-radius: .25rem;
$input-border-radius-lg: 1rem;
$input-border-color: setColor("dark", "darken-30");
$input-focus-border-color: setColor("dark", "darken-80");
$input-font-size: 1rem;
$input-font-size-lg: 1rem;

$input-padding-x-lg: map-get($spacers, 2);
$input-padding-y-lg: map-get($spacers, 2);

// Forms
$form-check-input-width: 1.25em;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-start: $form-check-input-width + 0.5em;
$form-check-margin-bottom: map-get($spacers, 2);
$form-check-transition: box-shadow 0.15s ease-in-out,
  border-color 0.15s ease-in-out, background-image 0.15s ease-in-out,
  color 0.15s ease-in-out;
$form-check-input-border: 2px solid #b3b3b3;
$form-check-input-checked-bg-color: setColor("primary", "p01");
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-focus-border: transparentize(setColor("primary", "p01"), 0.9);
$form-check-input-focus-box-shadow: 0 0 10px -2px #f38933;

$form-check-inline-margin-end: map-get($spacers, 2);

$form-select-padding-y-lg: $input-padding-y-lg;
$form-select-padding-x-lg: $input-padding-x-lg;
$form-select-border-radius-lg: $input-border-radius-lg;
$form-select-font-size-lg: $input-font-size-lg;

// Select
$form-select-bg-size: 16px 16px;
$form-select-indicator-color: setColor("dark", "darken-80");
$form-select-indicator: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik0wIDBoMTZ2MTZIMHoiLz4KICAgICAgICA8cGF0aCBkPSJtNy40NjggMTAuNzY0LTQuMjYtNC40ODhhLjc1Ni43NTYgMCAxIDEgMS4wOTgtMS4wNGwzLjcxIDMuOTEgMy42NzktMy44NzVhLjc1Ni43NTYgMCAxIDEgMS4wOTcgMS4wNDJsLTQuMjI2IDQuNDUxYS43NTUuNzU1IDAgMCAxLTEuMDk4IDB6IiBmaWxsPSIjMzMzIi8+CiAgICA8L2c+Cjwvc3ZnPgo=");

$form-select-border-color: $input-border-color;
$form-select-focus-width: 2px;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width
  setColor("dark", "darken-80");
$form-select-border-radius: $input-border-radius;
$form-select-focus-border-color: $input-focus-border-color;

$breadcrumb-divider: quote(">");
