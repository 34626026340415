.ui-component {
  /*border: 1px solid red;
  .ui-component {
    border: 1px solid #f08c4a;
    .ui-component {
      border: 1px solid yellow;
    }
  }*/
  .image {
    height: 5rem;
    max-width: 8rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

@for $n from 1% through 100% {
  .cs--h-#{$n} {
    @media (min-width: 992px) {
      height: $n + 0%;
    }
  }
  .cs--w-#{$n} {
    @media (min-width: 992px) {
      width: $n + 0%;
    }
  }
  .cs-max-w-#{n} {
    @media (min-width: 992px) {
      max-width: $n + 0%;
    }
  }
}

// COMPONENT SYSTEM GENERICI
.ui-component {
  &__content {
    width: inherit;
    height: inherit;
    &-wrapper {
      width: inherit;
      height: inherit;
      .e-title {
        margin-bottom: 1.5rem;
        h2 {
          font-size: 1.6rem;
        }
      }
    }
  }
  &.ui-grid {
    margin: 2rem 0;
  }
}

.cs--horizontal {
  .ui-component__children {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
    > * {
      flex: 1 1 auto;
      @media (min-width: 992px) {
        flex: 1 0 calc(25% - 2rem);
      }
    }
  }
  &.ui-grid--news-container {
    > .ui-component__content {
      > .ui-component__children {
        display: block;
        width: inherit;
        height: inherit;
      }
    }
  }
}

.ui-card--destination:not(.custom-card__wrapper) {
  flex: 1 1 calc(25% - 2rem);
  aspect-ratio: 1 / 1;
  height: 260px;
  margin: 0 auto 1.5rem auto;
  @media (max-width: 1200px) {
    height: 320px;
  }
  @media (max-width: 600px) {
    flex: auto;
  }
}

.ui-post--news-basic, .ui-post--static-page-body {
  @media (max-width: 768px) {
    width: 90% !important;
  }
}
