@import "../variables.scss";

@mixin notFound() {
  .not-found {
    min-height: 51vh;
    h1 {
      font-size: 1.8rem !important;
      color: $wtg-brand-color-03;
    }
    .alert-info {
      border-radius: 0 !important;
      background-color: $wtg-brand-color-04;
    }
  }
}
