// Colors
$brand-color-01: #00528c;
$brand-color-02: #57ADDE;
$brand-color-03: #FF7C28;
$brand-color-04: #999BAB;
$zeus-color-01: #6191E2;
$zeus-color-02: #C6E1FC;
$zeus-color-03: #E8F0FF;

$btn-bg-blue-01: #005B9A;
$btn-bg-blue-02: #273470;
$btn-bg-blue-03: #E9EAF0;
$btn-bg-orange-01: #FF7C28;
$btn-bg-orange-02: #E55717;
$btn-bg-orange-03: #FDF3E8;
$btn-bg-yellow-01: #FECD31;
$btn-bg-yellow-02: #DEA900;
$btn-bg-yellow-03: #FAF3DC;
$btn-bg-zeus-01: #6191E2;
$btn-bg-zeus-02: #437cdc;
$btn-bg-zeus-03: #dce9ff;
$btn-bg-green-01: #31B263;
$btn-bg-green-02: #066029;
$btn-bg-green-03: #B1F0C9;
$btn-bg-light-blue-01: #57ADDE;
$btn-bg-light-blue-02: #0079A6;
$btn-bg-light-blue-03: #99DBF4;
$btn-bg-grey: #999BAB;

$ui-color-01: #015287;
$ui-color-02: #ef8b19;
$ui-color-03: #33b7e9;
$ui-color-04: #888888;
$ui-color-05: #5A6872;
$ui-color-06: #f6f6f6;
$ui-color-07: #fff;

$yellow: #FFB400;

$text-color-primary: #343434;
$text-color-secondary: #5a6872;
$text-color-placeholder: #909090;

$color-warning: #fdd835;
$color-success: #7ed321;
$color-info: #40c4ff;
$color-danger: #ef2f4f;

$engine-bg: #DDEDFD;
