@import "mixin/ellipsis";
@import "modal";
@import "hr";
@import "components/search-engine";

.container-lg {
  @media (min-width: 1600px) {
    max-width: 1440px;
  }
  @media (min-width: 1440px) {
    max-width: 1320px;
  }
}
.container-sm {
  @media (min-width: 1600px) {
    max-width: 1180px;
  }
}

.star-coloured {
  font-size: 1.1rem;
  color: $yellow !important;
}

a:hover {
  text-decoration: none !important;
}

ngb-timepicker .ngb-tp .ngb-tp-input-container .sr-only {
  display: none;
}

.date-calendar .date-calendar_bar-item .page-link span.sr-only {
  display: none;
}

.ui-post--static-page-body .c-inner-post p {
  text-align: left !important;
}

.text-initial {
  text-align: initial;
}

ngb-pagination {
  .pagination {
    .page-item {
      .page-link {
        .sr-only {
          visibility: hidden;
        }
      }
    }
  }
}
