@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Montserrat:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,900;1,700&display=swap');

@mixin WTGtypography() {

  body {
    font-family: "Open Sans", sans-serif;
  }

  @font-face {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 2.5rem; //40px
    font-weight: 700;
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 2rem; //32px
    font-weight: 700;
  }

  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem; //24px
    font-weight: 700;
  }

  h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.375rem; //22px
    font-weight: 700;
  }

  h5 {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem; //16px
    font-weight: 700;
  }

  p {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0;
    line-height: 1.4;
  }

  label {
    font: 0.875rem/1.5 "Montserrat", sans-serif;
    letter-spacing: 0;
  }

  b {
    font-weight: 700;
  }
  .container-lg {
    @media (min-width: 1600px) {
      max-width: 1440px;
    }
    @media (min-width: 1440px) {
      max-width: 1320px;
    }
  }
  .container-sm {
    @media (min-width: 1600px) {
      max-width: 1180px;
    }
  }
}
