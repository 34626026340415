/* _variables.scss */
$input-border-radius: 0.25rem; /* 4px */
$check-box-dimention: 20px;
/* _variables.scss */

/* _forms.scss */
input[type="password"],
input[type="text"],
input[type="file"],
input[type="email"],
input[type="number"],
input[type="date"],
textarea,
select {
  /*padding: 0.63rem 2.4rem 0.63rem 0.75rem;*/
  font-size: 1rem; /* 14px */
  /*color: $ui-color-03;*/
  background-color: #fff;
  background-image: none;
  border: 0.0625rem /* 1px */ solid #ced4da;
  box-shadow: none;
  border-radius: $input-border-radius;
  @media (min-width: 997px) {
    font-size: 0.875rem; /* 14px */
  }

  &::placeholder {
    color: $text-color-placeholder;
  }

  &[disabled] {
    background-color: #f7f8fa !important;
    cursor: default !important;
    opacity: .7;
    &:hover {
      border-color: #ccc;
    }
  }
}

select {
  line-height: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  /*font-family: 'Roboto Mono', monospace;*/
}


/*input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}*/

@import '_form-group';
@import '_form-control';
@import '_form-check';
@import '_form-ng';
@import '_input-group';

.ng-invalid.ng-touched {
  @extend .is-invalid;
}

.custom-calendar-picker {
  position: relative;
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    z-index: 999;
  }
  &::after {
      content: "\e93f";
      font-family: '#{$icomoon-font-family}' !important;
      font-weight: 600;
      position: absolute;
      font-size: 1rem;
      right: .75rem;
      color: #57adde;
      top: 6px;
  }
  &.ng-invalid.ng-touched {
    &::after {
    right: 2rem;
  }
  }
}

/* _forms.scss END*/
