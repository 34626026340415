@import '../variables.scss';

@mixin storeLocator() {
  .store-locator-page-container {
    .side-store-locator-page {
      .side-store-locator-page-tabs {
        .side-store-locator-page-tab-agenzie, .side-store-locator-page-tab-percorso {
          color: #444 !important;
          border-color: #444 !important;

          &.selectedTab {
            background-color: $wtg-brand-color-01 !important;
            border-color: $wtg-brand-color-01 !important;
            color: #fff !important;
          }
        }
      }

      .side-store-locator-page-main {
        .side-store-locator-page-title {
          background-color: $wtg-brand-color-05 !important;
        }

        .side-store-locator-page-ul {
          .side-store-locator-page-agenzie {
            border-color: #444 !important;

            .side-store-locator-page-agenzia {
              color: #444 !important;
            }
          }
        }
      }
    }

    .side-store-locator-page-arrow {
      background-color: $wtg-brand-color-01 !important;
    }

    .nav-store-locator-page {
      padding: .25rem .5rem !important;
      background-color: #fff !important;
      box-shadow: none !important;

      .icon-store-locator-page {
        background-color: $wtg-brand-color-01 !important;
      }

      form {
        button {
          background-color: $wtg-brand-color-01 !important;
          border-color: $wtg-brand-color-01 !important;
        }

        #userInput {
          border-radius: 0 !important;
        }
      }
    }

    .side-store-locator-page-percorso {
      form {
        button {
          background-color: $wtg-brand-color-01 !important;
          border-color: $wtg-brand-color-01 !important;
        }
      }
    }
  }
  .btn.btn-primary.pseudo.store-locator-button {
    background-color: $wtg-brand-color-01 !important;
    &:hover {
      background-color: $wtg-brand-color-03 !important;
    }
  }
  button.gm-ui-hover-effect {
    width: 40px !important;
    height: 40px !important;
    top: 0 !important;
    right: 0 !important;
    span {
      width: 25px !important;
      height: 25px !important;
    }
  }
}
