@import '../variables.scss';

@mixin pagination() {
  .date-calendar {
    .pagination {
      border-radius: 0;
      .page-item {
        .page-link {
          border-radius: 0;
        }
      }
    }
    .date-calendar_bar {
      box-shadow: 0 6px 15px 5px rgba(0, 0, 0, .1);
      &-item {
        font-family: "Montserrat", sans-serif;
        &:not(.disabled) {
          &.active {
            .page-link {
              background-color: $wtg-brand-color-01;
              border-color: $wtg-brand-color-01;
              color: #fff;
            }
          }
          .page-link {
            span {
              color: #000;
            }
          }
        }
        &:first-child, &:last-child {
          span i {
            background-color: #EEF3F7;
            font-weight: 700;
          }
        }
      }
      &-link {
        &-date-number {
          font-weight: 500;
        }
      }
    }
  }
}
