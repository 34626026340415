@import '../variables.scss';

@mixin engine() {
  .engine {
    .container {
      max-width: 1180px;
    }
    .engine-container {
      border-radius: 0;
      background-color: rgba(140, 140, 140, 1);
      @media (min-width: 992px) {
        background-color: rgba(140, 140, 140, 0.9);
      }
      .engine-tabs {
        .engine__nav-link {
          color: #fff;
          border-radius: 0;
          padding: 0.5rem;
          margin: 0 0.25rem;
          min-width: 100px;
          &.active {
            background-color: #fff;
            span,
            i {
              color: $wtg-brand-color-05;
            }
            &:before {
              content: none;
            }
          }
          &:not(.active) {
            text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
          }
          span,
          i {
            color: #fff;
          }
          span {
            text-transform: uppercase;
            font-family: 'Open Sans', sans-serif;
            font-size: 0.875rem;
            display: block;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    .engine-body {
      .search-form {
        &__engine {
          label {
            font-family: 'Montserrat', sans-serif;
          }
        }
        &__element {
          // @media (max-width: 991px) {
          //   &.disabled, &[disabled] {
          //     display: none;
          //   }
          // }
        }
      }
    }
  }
}
