.u-scrollsnap {
  padding-top: map-get($spacers, 1);
  padding-bottom: map-get($spacers, 2);
  -webkit-overflow-scrolling: touch; /* Important for iOS devices */

  margin-right: calc(var(--margin) * 2) !important;
  margin-left: calc(var(--margin) * 2) !important;
  >* {
    &:first-of-type {
      padding-left: calc(var(--gutter) * 2) !important;
    }
    &:last-of-type {
      padding-right: calc(var(--gutter) * 2) !important;
    }
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    &--sm-max {
      display: flex;
      flex-wrap: nowrap;
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
      > * {
        flex: 1 0 auto;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    &--md-max {
      display: flex;
      flex-wrap: nowrap;
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
      > * {
        flex: 1 0 auto;
      }
    }
  }
  @media (max-width: map-get($grid-breakpoints, lg)) {
    &--lg-max {
      display: flex;
      flex-wrap: nowrap;
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
      > * {
        flex: 1 0 auto;
      }
    }
  }
  @media (max-width: map-get($grid-breakpoints, xl)) {
    &--xl-max {
      display: flex;
      flex-wrap: nowrap;
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
      > * {
        flex: 1 0 auto;
      }
    }
  }
  &--start {
    >* {
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
  }
  &--center {
    >* {
      scroll-snap-align: center;
      scroll-snap-stop: always;
    }
  }
  &--m0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
    >* {
      &:first-of-type {
        padding-left: 0 !important;
      }
      &:last-of-type {
        padding-right: 0 !important;
      }
    }
  }

}
