// Our function for shorter usage of map-get();
@function setColor($scheme, $tone: base) {
    @return map-get(map-get($colorscheme, $scheme), $tone);
}

// Usage: setColor('semantic', 's01');

$brand-color-01: #005B9A;
$brand-color-02: #57ADDE;
$brand-color-03: #FF7C28;
$brand-color-04: #999BAB;
// Scheme of colors
$colorscheme: (

  primary: (
    p01: #005B9A
  ),

  secondary: (
    s01: #57ADDE
  ),

  semantic: (
    success: #01c120,
    error: #fb0000,
    warning: #ffb602,
    info: #0072ca,
    disabled: rgba(0, 0, 0, 0.05)
  ),

  neutral: (
    n01: #fff,
    n02: #000
  ),

  support: (
    gusto: #cb9e55,
    idee: #81272c
  ),

  dark: (
    darken-0: rgba(0, 0, 0, 0),
    darken-5: rgba(0, 0, 0, 0.05),
    darken-10: rgba(0, 0, 0, 0.1),
    darken-20: rgba(0, 0, 0, 0.2),
    darken-30: rgba(0, 0, 0, 0.3),
    darken-40: rgba(0, 0, 0, 0.4),
    darken-60: rgba(0, 0, 0, 0.6),
    darken-80: rgba(0, 0, 0, 0.8),
    darken-90: rgba(0, 0, 0, 0.9),
    darken-100: rgba(0, 0, 0, 1),
  ),

  light: (
    lighten-0: rgba(255, 255, 255, 0),
    lighten-5: rgba(255, 255, 255, 0.05),
    lighten-10: rgba(255, 255, 255, 0.1),
    lighten-20: rgba(255, 255, 255, 0.2),
    lighten-30: rgba(255, 255, 255, 0.3),
    lighten-40: rgba(255, 255, 255, 0.4),
    lighten-60: rgba(255, 255, 255, 0.6),
    lighten-80: rgba(255, 255, 255, 0.8),
    lighten-90: rgba(255, 255, 255, 0.9),
    lighten-100: rgba(255, 255, 255, 1),
  ),

  ui: (
    background: #fff,
    background-ico: #F4F4F4,
    border-color: #ddd,
    box-shadow: #343434
  ),

  forms: (
    border: #b3b3b3,
    focus: #ffee9b,
    filled: #333333,
    disabled: rgba(0, 0, 0, 0.05),
  ),

  dividers: (
    subtle: rgba(0, 0, 0, 0.2),
    strong: #e20714,
    inverse: #f8f6ef
  ),

  icons: (
    enabled: #333333,
    active: #e20714,
    sub: rgba(0, 0, 0, 0.3),
    inverse: #fed404,
    selected: #faced0
  ),

  btnDirect: (
    normal: #f06c00,
    hover: #f27b19,
    disable: rgba(0, 0, 0, 0.05)
  ),

  btnInverse: (
    normal: rgba(0, 0, 0, 0.8),
    hover: rgba(0, 0, 0, 0.91),
    disabled: rgba(0, 0, 0, 0.05)
  )

);
