@import "./mixins.scss";

.brand-wtg {
  @include pageLoader();
  @include utilities();
  @include noResults();
  @include notFound();
  @include modal();
  @include WTGcontainer();
  @include WTGtypography();
  @include header();
  @include footer();
  @include buttons();
  @include pagination();
  @include home();
  @include news();
  @include engine();
  @include searchResults();
  @include searchEngine();
  @include detail();
  @include booking();
  @include bookingSteps();
  @include storeLocator();
  @include recap();
  @include destinations();
}


