@import '../variables.scss';

@mixin searchEngine() {
  .engine {
    .engine-body {
      border-radius: 0;
      background-color: #fff;
    }
    .engine-tabs {
      swiper {
        .swiper {
          &.s-wrapper {
            .swiper-button-next, .swiper-button-prev {
              background: linear-gradient(90deg, rgba(140, 140, 140, 0) 0%, rgba(140, 140, 140, .9) 30%, rgba(140, 140, 140, 1) 100%) !important;
              &:after {
                color: #fff !important;
              }
            }
            .swiper-button-prev {
              left: -20px !important;
            }
            .swiper-button-next {
              right: -20px !important;
            }
          }
        }
      }
    }
    .search-form {
      &__engine {
        .form-control-custom {
          &:focus {
            &:not([disabled]) {
              outline: 0;
              box-shadow: none !important;
            }
          }
        }
      }
    }
  }
}
