@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}

@font-face {
  font-family: "Roboto Slab", serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-family: "Roboto Slab", serif;
  font-size: 2.5rem; //40px
  font-weight: 700;
}

h2 {
  font-family: "Roboto Slab", serif;
  font-size: 2rem; //32px
  font-weight: 700;
}

h3 {
  font-family: "Roboto Slab", serif;
  font-size: 1.5rem; //24px
  font-weight: 700;
}

h4 {
  font-family: "Roboto Slab", serif;
  font-size: 1.375rem; //22px
  font-weight: 700;
}

h5 {
  font-family: "Roboto Slab", serif;
  font-size: 1rem; //16px
  font-weight: 700;
}

p {
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0;
}

label {
  font: 0.875rem/1.5 "Open Sans", sans-serif;
  letter-spacing: 0;
}

b {
  font-weight: 700;
}

.is-wtg {
  @font-face {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  h1, h2, h3, h4, h5 {
    font-family: "Montserrat", sans-serif !important;
  }
}
