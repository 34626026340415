@import '../variables.scss';

@mixin buttons() {
  .btn {
    font-family: 'Montserrat', sans-serif;
    border-radius: 0;
    &.btn {
      &--brand-color-03, &--light-blue, &--zeus {
        background-color: $wtg-brand-color-01;
        border: 1px solid $wtg-brand-color-01;
        color: #fff;
        font-weight: 400;
        &:hover {
          border-color: $wtg-brand-color-03;
          background-color: $wtg-brand-color-03;
          color: #fff;
          transition: color 0.2s ease-in;
        }
      }
      &--blue-outline {
        border: 1px solid $wtg-brand-color-01 !important;
        color: $wtg-brand-color-01 !important;
      }
    }
  }
}
